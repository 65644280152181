export const getScoreBy100AndColor = ({
  score,
  max,
}: {
  score: number
  max: number
}) => {
  const scoreBy100 = (score * 100) / max
  const color =
    scoreBy100 >= 75 ? 'green' : (scoreBy100 >= 50 && 'yellow') || 'red'

  return { scoreBy100, color }
}
