import { FunctionComponent } from 'react'

import { Flex, Grid, Heading, Text } from '@theme-ui/components'
import { shallowEqual } from 'react-redux'
import { useParams, Link } from 'react-router-dom'

import { useAreas } from 'hooks/useAreas'
import { useAreaProgress } from 'modules/areas/hooks/useAreaProgress'
import { State } from 'store/state'

import { Layout } from '../../layout/Layout'
import { PageTop } from '../../layout/PageTop'

import { useAppSelector } from '../../store/hooks'

import { AssessmentSidebar } from './AssessmentSidebar'
import Surveys from './components/Surveys'

const AreaDetail: FunctionComponent = () => {
  const { code } = useParams<{ code: string }>()
  const { area, viewingCompanyId } = useAppSelector(
    (state: State) => ({
      viewingCompanyId: state.apiHeadersReducer.companyId,
      area: state.areaReducer.area,
    }),
    shallowEqual
  )
  const { completed, total } = useAreaProgress({ area: { code } })

  const percent = Math.round((completed * 100) / total)
  const progress = percent || 0

  useAreas({})

  if (!viewingCompanyId) {
    return (
      <Layout>
        <p>Pelase select a company first</p>
        <Link to="/admin">Take me there</Link>
      </Layout>
    )
  }

  return (
    <Layout dark sidebar={<AssessmentSidebar />}>
      {area && (
        <>
          <PageTop>
            <Flex sx={{ alignItems: 'baseline' }}>
              <Heading>{area.title}</Heading>
            </Flex>
            <Text as="div" variant="subtitle" sx={{ color: 'grey400' }}>
              {progress}%
            </Text>
          </PageTop>
          <Grid columns={1} gap={4} sx={{ alignItems: 'flex-start' }}>
            <Surveys />
          </Grid>
        </>
      )}
    </Layout>
  )
}

export default AreaDetail
