import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { useAuditBy } from 'hooks/useAuditBy'

import { State } from 'store/state'

import { useAppSelector } from '../../../store/hooks'

export const useTitle = () => {
  const { t } = useTranslation()
  const { viewingSessionId, viewingApiVersion } = useAppSelector(
    (state: State) => ({
      viewingApiVersion: state.apiHeadersReducer.apiVersion,
      viewingSessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { assessmentPercentage, isCompanyAssessmentCompleted } = useAuditBy(
    viewingSessionId,
    viewingApiVersion
  )

  return {
    title: t('areas.assessment'),
    status: {
      title: `${assessmentPercentage}%`,
      type: isCompanyAssessmentCompleted
        ? 'completed'
        : (assessmentPercentage > 0 && 'reviewing') || 'pending',
    },
    done: isCompanyAssessmentCompleted,
    percent: assessmentPercentage,
  }
}
