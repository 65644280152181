import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import Icon from 'ui-kit/Icon'

import { GraphItem } from './types'

interface GraphLabelProps {
  item: GraphItem
  isHorizontal: boolean
}

export const GraphLabel: FunctionComponent<GraphLabelProps> = ({
  item,
  isHorizontal,
}) => {
  const verticalStyles =
    !isHorizontal &&
    ({
      order: 2,
    } as ThemeUIStyleObject)

  const horizontalStyles =
    isHorizontal &&
    ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: 320,
      order: 1,
      pr: 3,
    } as ThemeUIStyleObject)

  const arrowVerticalStyles =
    !isHorizontal &&
    ({
      position: 'absolute',
      bottom: 0,
      left: '50%',
      mb: 3,
      ml: -1,
    } as ThemeUIStyleObject)

  const arrowHorizontalStyles =
    isHorizontal &&
    ({
      transform: 'rotate(-90deg)',
      ml: 2,
    } as ThemeUIStyleObject)

  return (
    <Box
      sx={{
        flex: '0 0 auto',
        ...(isHorizontal ? horizontalStyles : verticalStyles),
      }}
    >
      <Text
        as="div"
        variant="label"
        sx={{
          textAlign: 'center',
          color: 'grey500',
          hyphens: 'auto',
        }}
      >
        {item.label}
      </Text>

      {item.url && (
        <Icon
          name="arrowDown"
          sx={{
            ...(isHorizontal ? arrowHorizontalStyles : arrowVerticalStyles),
          }}
          small
        />
      )}
    </Box>
  )
}
