import { FunctionComponent, useEffect } from 'react'

import { Heading } from '@theme-ui/components'
import { shallowEqual } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { Flex, Grid } from 'theme-ui'

import { Text } from '@fairhq/common'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { Layout } from 'layout/Layout'
import { PageTop } from 'layout/PageTop'
import { getAreas } from 'store/area/areaSlice'
import { AreaSource } from 'store/area/types'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { useScoreArea } from './hooks/useScoreArea'
import { ReportsSidebar } from './sidebar/ReportsSidebar'
import { SubAreaCard } from './SubAreaCard'
import { getScoreBy100AndColor } from './utils/getScoreBy100AndColor'

export const SubAreaPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { isAdmin } = useAuthRoles()
  const { code } = useParams<{ code: string }>()
  const { local, sub } = useScoreArea()

  const { apiVersion, sessionId, viewingCompanyId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
      viewingCompanyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })

  useEffect(() => {
    if (isScorable) {
      dispatch(getAreas({ source: AreaSource.COMPANY }))
    }
  }, [isScorable, dispatch])

  if (!viewingCompanyId && isAdmin) {
    return (
      <Layout>
        <p>Pelase select a company first</p>
        <Link to="/admin">Take me there</Link>
      </Layout>
    )
  }

  const parentArea = sub.find(area => area.code === code)
  if (!parentArea) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
  }

  const localAreas = local.filter(localArea => localArea.parent?.code === code)
  const { color, scoreBy100 } = getScoreBy100AndColor({
    score: parentArea.score,
    max: parentArea.areaMax,
  })

  return (
    <Layout sidebar={<ReportsSidebar />} dark>
      <PageTop>
        <Heading>{parentArea.title}</Heading>
        <Heading sx={{ color }}>{`${scoreBy100.toFixed(0)}%`}</Heading>
      </PageTop>
      {local.length > 0 && (
        <Grid columns={1} gap={4}>
          <Flex
            sx={{
              minWidth: 285,
              justifyContent: 'flex-end',
            }}
          >
            <Text element="label" type="body" size="sm" fontWeight={500}>
              Status | Benchmark
            </Text>
          </Flex>
          {localAreas.map(
            ({ code: localAreaCode, title, status, areaAirtableId }) => (
              <SubAreaCard
                key={localAreaCode}
                title={title}
                status={status}
                areaAirtableId={areaAirtableId}
              />
            )
          )}
        </Grid>
      )}
    </Layout>
  )
}
