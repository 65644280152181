import * as queryString from 'query-string'

import { emptySplitApi } from 'emptySplitApi'
import { ApiVersion, SessionId } from 'store/apiHeaders/types'
import { Area } from 'store/area/types'

import {
  DiversityMakeup,
  Insight,
  InsightGroup,
  InsightProjection,
  InsightType,
} from './types'

const insightsApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getInsightAreas: build.query<
      Partial<Area>[],
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `areas`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getInsight: build.query<
      Partial<Insight>,
      {
        type: InsightType
        apiVersion: ApiVersion
        sessionId: SessionId
      }
    >({
      query: ({ type, apiVersion, sessionId }) => ({
        url: `insights?${queryString.stringify({
          type,
        })}`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getInsightByArea: build.query<
      Partial<Insight>,
      {
        areaCode?: string
        projection?: InsightProjection
        group?: InsightGroup
        apiVersion: ApiVersion
        sessionId: SessionId
      }
    >({
      query: ({ areaCode = '', projection, group, apiVersion, sessionId }) => ({
        url: `insights/${areaCode}?${queryString.stringify({
          projection,
          group,
        })}`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    getDiversityMakeupInsight: build.query<
      DiversityMakeup[],
      {
        apiVersion: ApiVersion
        sessionId: SessionId
        breakdown?: boolean
      }
    >({
      query: ({ apiVersion, sessionId, breakdown }) => ({
        url: `insights/diversity/makeup?${queryString.stringify({
          breakdown,
        })}`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
  }),
})

export const {
  useGetInsightAreasQuery,
  useGetInsightQuery,
  useGetDiversityMakeupInsightQuery,
  useGetInsightByAreaQuery,
} = insightsApi
