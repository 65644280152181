import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { shallowEqual } from 'react-redux'

import { useLocation } from 'react-router'

import { ReactComponent as FairHQLogo } from 'assets/fairhq-logo.svg'
import Avatar from 'components/Avatar'
import { AccountMenu } from 'features/navigation/AccountMenu'
import { NavItems } from 'features/navigation/NavItems'
import { SessionDropdown } from 'features/navigation/SessionDropdown'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { useDropdown } from 'hooks/useDropdown'
import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import { State } from 'store/state'
import RouterLink from 'ui-kit/RouterLink'

import { useAppSelector } from '../../store/hooks'

export const NAVIGATION_HEIGHT = 74 // 72 + 2 border

export const Navigation: FunctionComponent = () => {
  const { isAdmin } = useAuthRoles()
  const params = useLocation()
  const isAdminTab = params.pathname === '/admin'

  const { user, viewingApiVersion } = useAppSelector(
    (state: State) => ({
      user: state.authReducer.user,
      viewingApiVersion: state.apiHeadersReducer.apiVersion,
    }),
    shallowEqual
  )

  const { data: account } = useGetAccountQuery({
    apiVersion: viewingApiVersion,
  })

  const { ref, opened, toggleDropdown } = useDropdown()

  return (
    <Flex
      sx={{
        position: 'relative',
        zIndex: 100,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: NAVIGATION_HEIGHT,
        pl: 7,
        pr: 8,
        bg: 'white',
        borderBottom: '2px solid',
        borderColor: 'grey100',
      }}
    >
      <RouterLink to="/" sx={{ fontSize: '0 !important' }}>
        <Box as={FairHQLogo} sx={{ fill: 'purple' }} />
        FairHQ
      </RouterLink>
      {isAdmin && account && (
        <Text variant="labelBold" sx={{ ml: 2 }}>
          Company: {account.companies[0].name}
        </Text>
      )}

      <Flex
        sx={{
          flex: '1 1 auto',
          alignSelf: 'stretch',

          justifyContent: 'center',
        }}
      >
        <NavItems />
      </Flex>

      <Flex
        ref={ref}
        sx={{ alignItems: 'center', position: 'relative', gap: '16px' }}
      >
        {!isAdminTab && <SessionDropdown />}
        <Avatar
          size={36}
          picture={user?.picture}
          user={user}
          onClick={toggleDropdown}
          // @ts-ignore
          sx={{ cursor: 'pointer' }}
        />
        {opened && <AccountMenu />}
      </Flex>
    </Flex>
  )
}
