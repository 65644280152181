import { FunctionComponent, ReactNode } from 'react'

import _ from 'lodash'

import { shallowEqual } from 'react-redux'
import { Container } from 'rsuite'
import styled from 'styled-components'

import { AreaFooter } from 'modules/areas/components/AreaFooter'
import { State } from 'store/state'

import { useAppSelector } from '../../store/hooks'

import { QuestionCard } from './QuestionCard'

const QuestionContainer = styled(Container)`
  position: relative;
`

interface QuestionsProps {
  header: ReactNode
  welcome?: ReactNode
  onSubmit: ({
    optionOrOptionsSelected,
    value,
    other,
  }: {
    optionOrOptionsSelected?: number | number[]
    value: string
    other: string
  }) => void
  onPrevious: () => void
  started: boolean
  loadingNext: boolean
  onSkip?: () => void
}

export const Questions: FunctionComponent<QuestionsProps> = ({
  onSkip,
  onPrevious,
  loadingNext,
  started,
  header,
  welcome,
  onSubmit,
}) => {
  const {
    selectedOptions,
    selectedOption,
    value,
    other,
    question,
    currentIndex,
    hasNext,
  } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      value: state.questionSessionReducer.value,
      other: state.questionSessionReducer.other,
      selectedOptions: state.questionSessionReducer.selectedOptions,
      selectedOption: state.questionSessionReducer.selectedOption,
      currentIndex: state.questionSessionReducer.currentIndex,
      hasNext: state.questionSessionReducer.hasNext,
    }),
    shallowEqual
  )

  function handleSubmit() {
    onSubmit({
      optionOrOptionsSelected:
        question?.type === 'multi' ? selectedOptions : selectedOption,
      value,
      other,
    })
  }

  return (
    <Container style={{ minHeight: '100vh' }}>
      {header}
      {started ? (
        <QuestionContainer>
          <QuestionCard />
          <AreaFooter
            disabledNext={
              !selectedOption && !value && _.isEmpty(selectedOptions)
            }
            disabledPrevious={currentIndex === 0}
            loadingNext={loadingNext}
            onNext={handleSubmit}
            onPrevious={onPrevious}
            onSkip={onSkip}
            hasNext={hasNext}
          />
        </QuestionContainer>
      ) : (
        welcome
      )}
    </Container>
  )
}
