import { Stripe } from 'store/customer/types'

export const convertToIntercomSubscription = (stripe: Stripe) => {
  const { plan } = stripe?.subscriptionItems?.[0] ?? {}

  return {
    customAttributes: {
      company_plan: plan?.nickname,
      stripe_id: stripe?.id,
      stripe_plan_id: plan?.id,
      stripe_plan_nickname: plan?.nickname,
      stripe_plan_price: plan?.amount
        ? Math.round(plan.amount / 100)
        : undefined,
      stripe_subscription_period_start_at: stripe?.start_date,
      stripe_subscription_status: stripe?.status,
      stripe_last_charge_at: stripe?.latest_invoice?.created,
    },
  }
}
