import { FunctionComponent } from 'react'

import { Grid, Flex, Text } from '@theme-ui/components'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { baseColors, makeTransition } from '@fairhq/common'
import { Benchmark } from 'store/score/types'

import { SpanProp } from './Dashbox'
import { useBenchmarks } from './hooks/useBenchmarks'
import { useScoreArea } from './hooks/useScoreArea'
import { formatSpan } from './utils/formatSpan'
import { getScoreBy100AndColor } from './utils/getScoreBy100AndColor'

const BenchmarkStyledCard = styled.div`
  display: flex;
  color: ${baseColors.grey500};
  background-color: ${baseColors.purple200};
  border-radius: 16px;
  align-items: center;
  padding: 8px 16px;
`

interface EDIOverviewProps {
  span: SpanProp
  benchmarGroupKey?: string
}

export const EDIOverview: FunctionComponent<EDIOverviewProps> = ({
  span,
  benchmarGroupKey,
}) => {
  const { sub } = useScoreArea()
  const { benchmarks } = useBenchmarks()

  const getBenchmarkValue = ({
    areaAirtableId,
    benchmarGroupKey,
  }: {
    areaAirtableId: string
    benchmarGroupKey: string
  }) => {
    const benchmark = benchmarks[benchmarGroupKey]?.find(
      (benchmark: Benchmark) =>
        benchmark.areas_airtable_ids.includes(areaAirtableId)
    )
    return benchmark
      ? `${benchmarGroupKey.split(' - ')[0]} ${benchmark.value}%`
      : undefined
  }

  return (
    <Grid gap={2} sx={{ gridColumn: formatSpan(span), gridRow: 2 }}>
      {sub.length > 0 &&
        sub.map(
          ({ title, score, areaId, areaCode, areaAirtableId, areaMax }) => {
            const { color, scoreBy100 } = getScoreBy100AndColor({
              score,
              max: areaMax,
            })

            return (
              <Link key={areaId} to={`/reports/${areaCode}`}>
                <Flex
                  sx={{
                    borderRadius: 20,
                    bg: 'white',
                    justifyContent: 'space-between',
                    transition: `${makeTransition('box-shadow')}`,
                    '&:hover': {
                      boxShadow: '0px 3px 8px rgba(10, 27, 64, 0.15)',
                    },
                  }}
                >
                  <Flex sx={{ alignItems: 'center', px: 6, py: 1 }}>
                    <Text as="div" variant="h3">
                      {title}
                    </Text>
                  </Flex>
                  <Flex
                    sx={{
                      px: 6,
                      py: 1,
                      minHeight: '82px',
                      alignItems: 'center',
                      gap: '16px',
                    }}
                  >
                    <Text
                      as="div"
                      variant="title"
                      sx={{ color, marginRight: '18px' }}
                    >
                      {`${scoreBy100.toFixed(0)}%`}
                    </Text>
                    {benchmarGroupKey && (
                      <BenchmarkStyledCard>
                        <Text
                          as="div"
                          sx={{ fontWeight: 'normal', fontSize: '12px' }}
                        >
                          {getBenchmarkValue({
                            benchmarGroupKey,
                            areaAirtableId,
                          })}
                        </Text>
                      </BenchmarkStyledCard>
                    )}
                  </Flex>
                </Flex>
              </Link>
            )
          }
        )}
    </Grid>
  )
}
