import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersion, SessionId } from 'store/apiHeaders/types'

import { AreaCodeStatus, Company } from './types'

// TODO: once all calls in the account reducer are converted to use
// RTK Query, rename this file to auditApi

export const auditApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getIsScorable: build.query<
      boolean,
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `/audit/scorable`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      providesTags: [TagTypes.SCORABLE],
    }),

    getAuditCompany: build.query<
      Company,
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `audit/company`,
        headers: {
          'fairhq-version': apiVersion,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getAreaCodeStatus: build.query<
      AreaCodeStatus,
      {
        code?: string
        apiVersion: ApiVersion
        sessionId: SessionId
      }
    >({
      query: ({ code, apiVersion, sessionId }) => ({
        url: `/areas/${code}/status`,
        headers: {
          'fairhq-version': apiVersion,
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
  }),
})

export const {
  useGetIsScorableQuery,
  useGetAuditCompanyQuery,
  useGetAreaCodeStatusQuery,
} = auditApi
