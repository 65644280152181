import { User } from '@auth0/auth0-spa-js'
import Bugsnag from '@bugsnag/js'
import * as FullStory from '@fullstory/browser'
import {
  createSlice,
  PayloadAction,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'

import { config } from '@fairhq/common'

import { Roles } from 'hooks/useAuthRoles'
import { accountApi } from 'store/account/accountApi'
import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAll } from 'store/helpers/isClearAll'
import { isRejected } from 'store/helpers/isRejected'

import { QueryStatus } from 'store/types'

import { AuthState } from './types'

export const clear = createAction('auth/clear')

export const getIntercomHash = createAsyncThunk(
  'auth/getIntercomHash',
  async (_, { getState }) => {
    const { data } = await accountApi.getIntercomHash(getState)
    return data
  }
)

const initialState: Partial<AuthState> = { userQueryStatus: QueryStatus.IDLE }

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.userQueryStatus = QueryStatus.LOADING
      if (!action?.payload) {
        return
      }
      state.user = action.payload || {}
      state.user.user_metadata = {
        ...(action.payload?.user_metadata ?? {}),
        ...(action.payload?.['https://fairhq.co/user_metadata'] ?? {}),
      }
      state.user.app_metadata = {
        ...(action.payload?.app_metadata ?? {}),
        ...(action.payload?.['https://fairhq.co/app_metadata'] ?? {}),
      }
      const { sub: id, email, name } = action.payload
      window?.gtag?.('config', config.analytics, { userId: id })

      Bugsnag.setUser(id, email, name)

      const isAdmin = state.user?.app_metadata?.roles?.includes(Roles.ADMIN)

      if (isAdmin) {
        FullStory.shutdown()
      } else {
        FullStory.identify(state.user?.id || '', {
          email: state.user.email,
          displayName: state.user?.name ?? state.user.email,
          jobTitle:
            state.user?.user_metadata?.role_other ||
            state.user?.user_metadata?.role,
        })
      }
      state.userQueryStatus = QueryStatus.SUCCESS
    },
    setJWT(state, action) {
      const decodedToken: { permissions: string[] } = jwtDecode(action.payload)
      state.hasPermissions = decodedToken?.permissions?.length > 0
      state.jwt = action.payload
      localStorage.setItem(config.tokenKey, action.payload || '')
    },
  },
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(getIntercomHash.fulfilled, (state, action) => {
        state.intercom = action.payload
      })
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isRejected('auth'), handleErrorState)
  },
})

export const { actions: authActions, reducer: authReducer } = authSlice
