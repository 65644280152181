import { FunctionComponent, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Select } from '@theme-ui/components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import styled from 'styled-components'

import { baseColors, Button, Text } from '@fairhq/common'
import useSchema from 'hooks/use-yup-schema'
import { useCreateAccountMutation } from 'store/account/accountApiWithQuery'
import Field from 'ui-kit/Field'

const Container = styled.div`
  border-radius: 20px;
  width: 100%;
`

const Header = styled.div`
  background-color: ${baseColors.purple700};
  padding: 40px 60px 32px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-align: center;
`

const Content = styled.form`
  background-color: ${baseColors.white};
  padding: 32px 48px 48px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`

const ButtonsContainer = styled.div`
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & button:not(:last-child) {
    margin-right: 16px;
  }
`

const ErrorText = styled(Text)`
  color: ${baseColors.red};
  margin-top: 24px;
  align-self: end;
`
const StyledText = styled(Text)`
  width: 100%;
  text-align: left;
`

type AddCompanyModalProps = {
  onClose: () => void
}

type FormData = {
  email: string
  name: string
  size: string
}

export const AddCompanyModal: FunctionComponent<AddCompanyModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation()
  const [createAccount, { isLoading, isSuccess, isError }] =
    useCreateAccountMutation()

  const schema = useSchema(['email', 'name', 'size'])
  const sizeOptions = ['small', 'large']

  const { register, handleSubmit, formState } = useForm<FormData>({
    defaultValues: { email: '', name: '', size: sizeOptions[0] },
    resolver: yupResolver(schema),
  })

  const { errors, isValid } = formState

  const onSubmit = ({ email, name, size }: FormData) => {
    createAccount({ name, email, size })
  }

  useEffect(() => {
    if (isSuccess && !isLoading) {
      onClose()
    }
  }, [isSuccess, isLoading, onClose])

  return (
    <Modal isOpen onRequestClose={onClose} shouldCloseOnOverlayClick>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <Text
            type="body"
            element="h2"
            size="md"
            fontWeight={600}
            color={baseColors.white}
          >
            Add company
          </Text>
        </Header>
        <Content>
          <Field
            label="Company name"
            {...register('name')}
            type="input"
            placeholder="Company name"
            sx={{
              width: '100%',
              maxWidth: '100%',
            }}
            error={errors.name?.message}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <StyledText element="label" size="xs" fontWeight={700} type="body">
              Company size
            </StyledText>

            <Select
              {...register('size')}
              sx={{
                width: '100%',
                maxWidth: '100%',
                '&:focus': {
                  borderColor: baseColors.purple,
                },
              }}
            >
              {sizeOptions.map(sizeOption => (
                <option key={sizeOption} value={sizeOption}>
                  {t(`dvc.${sizeOption}`)}
                </option>
              ))}
            </Select>
          </Box>
          <Field
            label="Email for invitation"
            {...register('email')}
            type="email"
            placeholder="Email"
            sx={{
              width: '100%',
              maxWidth: '100%',
            }}
            error={errors.email?.message}
          />
          <StyledText element="p" size="xs" fontWeight={400} type="body">
            Heads up: Inviting a company begins their relationship with Fair HQ
            and triggers the partnership fee invoice (30 day terms).
          </StyledText>
          <ButtonsContainer>
            <Button
              fullWidth
              loading={isLoading}
              disabled={!isValid}
              type="submit"
            >
              Invite
            </Button>
            <Button variant="tertiary" fullWidth onClick={onClose}>
              Cancel
            </Button>
          </ButtonsContainer>
          {isError && (
            <ErrorText element="p" type="body" size="xs" fontWeight={600}>
              Something went wrong. Plase try again.
            </ErrorText>
          )}
        </Content>
      </Container>
    </Modal>
  )
}
