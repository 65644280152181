import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

import { Animation } from 'rsuite'

import styled, { css } from 'styled-components'

import { Spinner } from '@fairhq/common'

import { useCheckPermissions } from 'hooks/useCheckPermissions'
import { SignUpLayout } from 'layout/SignUpLayout'

import { SlideContainer } from '../../../styled'
import { useCheckGoogleUserIsInvited } from '../hooks/useCheckGoogleUserIsInvited'

import { DetailsForm } from './DetailsForm'
import { WelcomeSteps } from './WelcomeSteps'

const WelcomeStep = styled(Animation.Slide)`
  ${({ in: visible }) =>
    !visible &&
    css`
      display: none;
    `}
`

const WelcomeComponent = () => {
  const { t } = useTranslation()
  const { isAuthenticated, isLoading } = useAuth0()
  const permissionsChecked = useCheckPermissions()
  const isInvitedGoogleUser = useCheckGoogleUserIsInvited()

  if (
    !isAuthenticated ||
    isLoading ||
    !permissionsChecked ||
    isInvitedGoogleUser !== false
  ) {
    return <Spinner backdrop vertical />
  }

  return (
    <SignUpLayout>
      <WelcomeSteps title={t('hello.personalInfo.title')}>
        <WelcomeStep in>
          {(props, ref) => (
            <SlideContainer {...props} ref={ref}>
              <DetailsForm />
            </SlideContainer>
          )}
        </WelcomeStep>
      </WelcomeSteps>
    </SignUpLayout>
  )
}

export const Welcome = React.memo(WelcomeComponent)
