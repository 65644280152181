import React from 'react'

import { Box, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { baseColors } from '@fairhq/common'

import { useAppSelector } from '../../store/hooks'
import { State } from '../../store/state'

import { ReactComponent as Level0 } from './assets/0-level-seal.svg'
import { ReactComponent as Level1 } from './assets/1-level-seal.svg'
import { ReactComponent as Level2 } from './assets/2-level-seal.svg'
import { useGetBadgeQuery } from './store/badgesApi'
import { formatSpan } from './utils/formatSpan'

export const Certification: React.FunctionComponent<{ span: number }> = ({
  span,
}) => {
  const { t } = useTranslation()
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: badge } = useGetBadgeQuery({ apiVersion, sessionId })

  return (
    <Box
      sx={{
        backgroundColor: baseColors.white,
        borderRadius: '20px',
        padding: `30px 38px`,
        gridColumn: formatSpan(span),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        svg: { maxHeight: '330px', maxWidth: '100%' },
      }}
    >
      <Text
        as="div"
        sx={{
          marginBottom: '8px',
          textAlign: 'left',
          fontWeight: 700,
          fontSize: '16px',
          lineHeight: '30px',
        }}
      >
        {t('dvc.certification')}
      </Text>

      <Box sx={{ marginTop: '8px' }}>
        {badge?.level === 1 ? (
          <Level1 />
        ) : (
          (badge?.level === 2 && <Level2 />) || <Level0 />
        )}
      </Box>
    </Box>
  )
}
