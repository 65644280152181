import { FunctionComponent } from 'react'

import Modal from 'react-modal'
import styled from 'styled-components'

import { baseColors, Button, Text } from '@fairhq/common'

const Container = styled.div`
  border-radius: 20px;
  width: 100%;
`

const Header = styled.div`
  background-color: ${baseColors.purple700};
  padding: 40px 60px 32px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  text-align: center;
`

const Content = styled.div`
  background-color: ${baseColors.white};
  padding: 32px 48px 48px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: center;
`

const ButtonsContainer = styled.div`
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & button:not(:last-child) {
    margin-right: 16px;
  }
`

interface ConfirmNewSessionModalProps {
  onClose: () => void
  onSubmit: () => void
  title: string
  body: string
  isLoading: boolean
}
export const ConfirmNewSessionModal: FunctionComponent<
  ConfirmNewSessionModalProps
> = ({ onClose, onSubmit, title, body, isLoading }) => (
  <Modal isOpen onRequestClose={onClose} shouldCloseOnOverlayClick>
    <Container>
      <Header>
        <Text
          type="body"
          element="h2"
          size="md"
          fontWeight={600}
          color={baseColors.white}
        >
          {title}
        </Text>
      </Header>
      <Content>
        <Text
          type="body"
          element="p"
          size="sm"
          fontWeight={500}
          color={baseColors.purple900}
        >
          {body}
        </Text>

        <ButtonsContainer>
          <Button variant="tertiary" fullWidth onClick={onClose}>
            Cancel and return to current session
          </Button>
          <Button loading={isLoading} fullWidth onClick={onSubmit}>
            Copy to new session
          </Button>
        </ButtonsContainer>
      </Content>
    </Container>
  </Modal>
)
