import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Container, Animation } from 'rsuite'
import styled from 'styled-components'

import { Spinner } from '@fairhq/common'
import { questionSessionActions } from 'store/questionSession/questionSessionSlice'
import { State } from 'store/state'
import { Subtitle } from 'styled'
import { Message } from 'styled/message'

import { useAppSelector, useAppDispatch } from '../../store/hooks'

import { QuestionTypeCard } from './QuestionTypeCard'

const QuestionCardContainer = styled.div<{ type?: string }>`
  max-width: 800px;
  margin: 0 auto 20px;
  align-items: center;
  min-height: ${({ type }) =>
    type === 'single' || type === 'multi' ? '400px' : 'auto'};
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;

  ${Subtitle} {
    margin-bottom: 8px;
    text-align: center;
    justify-content: center;
  }

  ${Message} {
    text-align: center;

    &[type='indication'] {
      margin-top: 18px;
      margin-bottom: 0;
    }
  }
`

const QuestionCardHeaderContainer = styled.div<{ type?: string }>`
  padding-top: ${({ type }) =>
    type === 'single' || type === 'multi' ? '80px' : '0'};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  width: 100%;
  text-align: center;
  padding-bottom: 30px;

  p {
    text-align: center;
    justify-content: center;
  }
`

const QuestionTypeCardContainer = styled(Container)`
  width: 100%;
  flex: initial;

  .rs-radio-group-picker .rs-radio-checker > label,
  .rs-radio-group-picker .rs-radio-checker > label:hover,
  .rs-radio-group-picker .rs-radio-checker > label:focus {
    color: ${({ theme }) => theme.colors.grey500};
  }
`

export const QuestionCard = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    questionIndex,
    question,
    numberOrTextValue,
    selectedOption,
    selectedOptions,
    other,
  } = useAppSelector(
    (state: State) => ({
      question: state.questionSessionReducer.question,
      questionIndex: state.questionSessionReducer.currentIndex + 1,
      numberOrTextValue: state.questionSessionReducer.value,
      selectedOption: state.questionSessionReducer.selectedOption,
      selectedOptions: state.questionSessionReducer.selectedOptions ?? [],
      other: state.questionSessionReducer.other,
    }),
    shallowEqual
  )
  const { title, description } = question ?? {}
  const { type } = question ?? {}

  if (!type) {
    return <Spinner backdrop vertical content={t('loading')} />
  }

  return (
    <Animation.Slide in placement="bottom">
      {(props, ref) => (
        <QuestionCardContainer
          type={type}
          ref={ref}
          {...props}
          key={question?.code}
        >
          <QuestionCardHeaderContainer type={type}>
            <Subtitle>
              {t('question.number', { number: questionIndex })}
            </Subtitle>
            <Message type="question">{title}</Message>
            {description ? (
              <Message type="indication">{description}</Message>
            ) : undefined}
          </QuestionCardHeaderContainer>
          {question && (
            <QuestionTypeCardContainer>
              <QuestionTypeCard
                question={question}
                onNumberOrTextChange={(newValue: string) => {
                  dispatch(questionSessionActions.setValue(newValue))
                }}
                numberOrTextValue={numberOrTextValue}
                selectedOption={selectedOption}
                setSelectedOption={(value: any) => {
                  dispatch(questionSessionActions.setSelectedOption(value))
                }}
                selectedOptions={selectedOptions}
                setSelectedOptions={(values: any) => {
                  dispatch(questionSessionActions.setSelectedOptions(values))
                }}
                other={other}
                setOther={(value: string) =>
                  dispatch(questionSessionActions.setOther(value))
                }
              />
            </QuestionTypeCardContainer>
          )}
        </QuestionCardContainer>
      )}
    </Animation.Slide>
  )
}
