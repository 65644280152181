import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { ApiVersion, SessionId } from 'store/apiHeaders/types'

import { DVCBenchmarks, DVCScores, Scores } from './types'

const scoreApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getLatestScores: build.query<
      DVCScores[],
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `/companies/score`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      providesTags: [TagTypes.SCORES],
    }),
    getScoresForSession: build.query<
      Scores,
      { sessionId: SessionId | void; apiVersion: ApiVersion }
    >({
      query: ({ sessionId, apiVersion }) => ({
        url: `companies/score`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
    generateScores: build.mutation<
      void,
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `companies/score/generate`,
        method: 'POST',
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
      invalidatesTags: [TagTypes.SESSIONS, TagTypes.SCORES],
    }),
    getBenchmarks: build.query<
      DVCBenchmarks,
      { sessionId: SessionId | void; apiVersion: ApiVersion }
    >({
      query: ({ sessionId, apiVersion }) => ({
        url: `companies/benchmarks`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
  }),
})

export const {
  useGetLatestScoresQuery,
  useGetScoresForSessionQuery,
  useGenerateScoresMutation,
  useGetBenchmarksQuery,
} = scoreApi
