import { FunctionComponent, useEffect, useState } from 'react'

import styled from 'styled-components'
import { Box, Flex, Grid } from 'theme-ui'

import { baseColors, Text as FairHQText, Text } from '@fairhq/common'
import { StateHandler } from 'components/StateHandler'
import { Benchmark } from 'store/score/types'
import { QueryStatus } from 'store/types'

import { BenchmarksDropdown } from './BenchmarksDropdown'
import { Certification } from './Certification'
import { EDIOverview } from './EDIOverview'
import { Graph } from './graph/Graph'
import { useBenchmarks } from './hooks/useBenchmarks'
import { useScoreArea } from './hooks/useScoreArea'
import { getScoreBy100AndColor } from './utils/getScoreBy100AndColor'

const PlainText = styled(Text)`
  margin: 0;
`

export const ReportsWithScores: FunctionComponent = () => {
  const { general, queryStatus } = useScoreArea()

  const {
    bechmarckGroupKeys,
    queryStatus: benchmarkQueryStatus,
    benchmarks,
  } = useBenchmarks()

  const [benchmarGroupKey, setBenchmarkGroupKey] = useState(
    bechmarckGroupKeys?.length > 0 ? bechmarckGroupKeys[0] : undefined
  )

  useEffect(() => {
    if (!benchmarGroupKey) {
      if (bechmarckGroupKeys.length > 0) {
        setBenchmarkGroupKey(bechmarckGroupKeys[0])
      }
    }
  }, [benchmarGroupKey, bechmarckGroupKeys])

  if (queryStatus === QueryStatus.SUCCESS) {
    const getBenchmarkValue = ({
      areaAirtableId,
      benchmarGroupKey,
    }: {
      areaAirtableId: string
      benchmarGroupKey: string
    }) => {
      const benchmark = benchmarks[benchmarGroupKey]?.find(
        (benchmark: Benchmark) =>
          benchmark.areas_airtable_ids.includes(areaAirtableId)
      )
      return {
        value: benchmark?.value || 0,
        label: benchmarGroupKey.split(' - ')[0].concat(' benchmark') || '',
      }
    }

    const { color: globalScoreColor, scoreBy100 } = getScoreBy100AndColor({
      score: general?.score ?? 0,
      max: general?.areaMax ?? 0,
    })
    return (
      <StateHandler
        isSuccess={benchmarkQueryStatus === QueryStatus.SUCCESS}
        isLoading={benchmarkQueryStatus === QueryStatus.LOADING}
        isError={benchmarkQueryStatus === QueryStatus.ERROR}
      >
        <Grid columns={6} gap={4}>
          {general && benchmarGroupKey && (
            <Box
              sx={{
                gridColumn: 'span 3',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                borderRadius: 20,
                bg: 'white',
                px: 8,
                py: 5,
                mb: 5,
                height: '100%',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
              >
                <PlainText element="p" type="body" size="sm" fontWeight={700}>
                  Overview
                </PlainText>
                <PlainText element="p" type="body" size="xs" fontWeight={400}>
                  The percentage of recommended policies, programs, and best
                  practices that your fund has in place.
                </PlainText>
              </Box>
              <Graph
                horizontal={false}
                max={100}
                items={[
                  {
                    value: scoreBy100,
                    label: 'Your score',
                    color: globalScoreColor,
                  },
                  {
                    color: baseColors.grey300,
                    ...getBenchmarkValue({
                      benchmarGroupKey,
                      areaAirtableId: general?.areaAirtableId || '',
                    }),
                  },
                ]}
              />
            </Box>
          )}
          <Certification span={3} />
          <Flex
            sx={{ gridColumn: 'span 6', flexDirection: 'column', gap: '24px' }}
          >
            <Flex sx={{ justifyContent: 'end' }}>
              <Flex
                sx={{
                  alignSelf: 'end',
                  minWidth: 285,
                  alignItems: 'center',
                }}
              >
                <FairHQText
                  element="label"
                  type="body"
                  size="sm"
                  fontWeight={500}
                >
                  Your score |
                </FairHQText>
                <BenchmarksDropdown
                  benchmarks={bechmarckGroupKeys}
                  value={benchmarGroupKey}
                  onChange={e => {
                    setBenchmarkGroupKey(e?.target?.value.toString())
                  }}
                />
              </Flex>
            </Flex>

            <EDIOverview benchmarGroupKey={benchmarGroupKey} span={6} />
          </Flex>
        </Grid>
      </StateHandler>
    )
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>
}
