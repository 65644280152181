import { useTranslation } from 'react-i18next'
import { NumberType, StringType, BooleanType } from 'schema-typed'

export default function useSchema() {
  const { t } = useTranslation()
  const schema: Record<string, any> = {
    password: StringType().isRequired(t('form.required')),
    confirmPassword: StringType()
      .isRequired(t('form.required'))
      .addRule(
        (value, data) => value === data.password,
        t('form.password.confirmPasswordNotMatch')
      ),
    email: StringType()
      .isEmail(t('form.email.invalid'))
      .isRequired(t('form.required')),
    toc: BooleanType().isRequired(t('form.required')),
    consent: BooleanType().isRequired(t('form.required')),
    level: StringType().isRequired(t('form.required')),
    department: StringType(),
    employment_type: StringType(),
    vat: StringType().pattern(
      /^(GB)([0-9]{9}|[0-9]{12})$/,
      t('form.vat.invalid')
    ),
    setup: StringType(),
    workingHours: NumberType().min(0, t('form.min', { min: 0 })),
    salary: NumberType().min(0, t('form.min', { min: 0 })),
    bonus: NumberType().min(0, t('form.min', { min: 0 })),
    name: StringType().isRequired(t('form.required')),
    sector: StringType().isRequired(t('form.required')),
    size: StringType().isRequired(t('form.required')),
    location: StringType().isRequired(t('form.required')),
    firstName: StringType().isRequired(t('form.required')),
    lastName: StringType().isRequired(t('form.required')),
    role: StringType(),
    role_other: StringType().addRule(
      (value, data) => (data?.role === 'other' ? !!value : true),
      t('form.required'),
      true
    ),
    city: StringType(),
    country: StringType(),
    line1: StringType().isRequired(t('form.required')),
    line2: StringType(),
    postal_code: StringType().isRequired(t('form.required')),
    state: StringType(),
  }

  return schema
}
