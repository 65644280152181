import { User } from '@auth0/auth0-spa-js'

import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { ApiVersion, ApiVersionOptions } from 'store/apiHeaders/types'
import { companyActions } from 'store/company/companySlice'

import { Account } from './types'

// TODO: once all calls in the account reducer are converted to use
// RTK Query, rename this file to accountApi

const accountApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getAccount: build.query<Account, { apiVersion: ApiVersion }>({
      query: ({ apiVersion }) => ({
        url: `/account`,
        headers: { 'fairhq-version': apiVersion || '' },
      }),
      providesTags: [TagTypes.ACCOUNT],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: account } = await queryFulfilled
        if (account?.companies?.length > 0) {
          const company = account?.companies?.[0]
          dispatch(companyActions.setCompany(company))
          dispatch(apiHeadersActions.setCompanyId(company?.id))
        }

        const accountId = account?.id
        if (accountId) {
          dispatch(apiHeadersActions.setAccountId(accountId))
        }
      },
    }),
    getAccounts: build.query<Account[], void>({
      query: () => ({
        url: '/account/accounts',
        headers: { 'fairhq-version': ApiVersionOptions.V2 },
      }),
      providesTags: [TagTypes.ACCOUNTS],
    }),
    createAccount: build.mutation<
      Account,
      { email: string; name: string; size: string }
    >({
      query: ({ email, name, size }) => ({
        url: '/account',
        method: 'POST',
        headers: { 'fairhq-version': ApiVersionOptions.V2 },
        body: { email, name, size },
      }),
      invalidatesTags: [TagTypes.ACCOUNTS],
    }),

    getUsers: build.query<User[], { accountId: number }>({
      query: ({ accountId }) => ({
        url: '/account/users',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
          'fairhq-account': accountId.toString(),
        },
      }),
    }),
  }),
})

export const {
  useGetAccountQuery,
  useGetAccountsQuery,
  useCreateAccountMutation,
  useGetUsersQuery,
} = accountApi
