import { FunctionComponent } from 'react'

import { Box } from '@theme-ui/components'

import { useLocation } from 'react-router'

import Sidebar from 'layout/Sidebar'

import { QueryStatus } from 'store/types'

import { useReportsSidebar } from '../hooks/useReportsSidebar'

import { EmptySidebar } from './EmptySidebar'
import { MenuItem } from './MenuItem'

export const ReportsSidebar: FunctionComponent = () => {
  const location = useLocation()
  const { sidebarItems, status } = useReportsSidebar()

  if (status === QueryStatus.SUCCESS) {
    return (
      <Sidebar sx={{ py: 6 }}>
        <Box sx={{ position: 'sticky', bottom: 0 }}>
          <Box sx={{ mb: 5 }}>
            {sidebarItems?.map(({ text, url, code }, index) => (
              <MenuItem
                key={`${code}_${index}`}
                text={text}
                url={url}
                active={url ? location.pathname.startsWith(url) : false}
              />
            ))}
          </Box>
        </Box>
      </Sidebar>
    )
  }

  return <EmptySidebar />
}
