import { groupBy } from 'lodash'
import { shallowEqual } from 'react-redux'

import { useAppSelector } from 'store/hooks'
import { useGetBenchmarksQuery } from 'store/score/scoreApi'
import { Benchmark } from 'store/score/types'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

export const useBenchmarks = () => {
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )

  const { data, isLoading, isSuccess } = useGetBenchmarksQuery({
    apiVersion,
    sessionId,
  })

  if (isSuccess) {
    const benchMarkAreasByGroup: { [key: string]: Benchmark[] } = groupBy(
      data.benchmarks,
      'group'
    )

    return {
      localAreasBenchmark: data.areas,
      benchmarks: benchMarkAreasByGroup,
      bechmarckGroupKeys: data.groupLabels,
      queryStatus: QueryStatus.SUCCESS,
    }
  }
  return {
    localAreasBenchmark: [],
    benchmarks: {},
    bechmarckGroupKeys: [],
    queryStatus: isLoading ? QueryStatus.LOADING : QueryStatus.ERROR,
  }
}
