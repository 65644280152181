import React, { FunctionComponent } from 'react'

import { Box, Heading, Text } from '@theme-ui/components'
import { Trans, useTranslation } from 'react-i18next'

import Button from './Button'

type Props = {
  titleKey: string
  textKey: string
  illustComp?: FunctionComponent
  buttonText?: string
  buttonOnClick?: (event: React.PointerEvent<HTMLButtonElement>) => void
}

const Blankslate: FunctionComponent<Props> = ({
  titleKey,
  textKey,
  illustComp,
  buttonText,
  buttonOnClick,
}) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ textAlign: 'center', m: 'auto' }}>
      <Heading sx={{ mb: 3, mt: 7 }}>
        <Trans i18nKey={titleKey} />
      </Heading>
      <Text as="div" variant="bodyLarge" sx={{ mb: 5 }}>
        <Trans i18nKey={textKey} />
      </Text>
      {illustComp ? (
        <Box as={illustComp} sx={{ mb: 4, borderRadius: '20px' }} />
      ) : null}

      {buttonText && buttonOnClick ? (
        <Button size="large" onClick={buttonOnClick}>
          {t(buttonText)}
        </Button>
      ) : null}
    </Box>
  )
}

export default Blankslate
