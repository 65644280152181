import { ApiVersionOptions } from 'store/apiHeaders/types'
import { http } from 'utils/http'

import { Company } from './types'

export const companyApi = {
  addCompany(getState: () => unknown, company: Partial<Company>) {
    return http.post(getState, 'companies', company)
  },
  updateCompany(
    getState: () => unknown,
    id: number,
    company: Partial<Company>
  ) {
    return http.patch(getState, `companies/${id}`, company)
  },
  getAPIVersion(getState: () => unknown) {
    return http.get(getState, `companies/version`, {
      'fairhq-version': ApiVersionOptions.V2,
    })
  },
}
