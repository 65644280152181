import { User } from '@auth0/auth0-react'

export const convertToIntercomUser = (user: User) => ({
  name: user?.name ?? user.email,
  email: user.email,
  createdAt: user?.created_at,
  customAttributes: {
    role: user?.user_metadata?.roles?.[0] || user?.app_metadata?.roles?.[0],
    first_name: user?.given_name,
    last_name: user?.family_name,
  },
})
