import { orderBy } from 'lodash'
import { shallowEqual } from 'react-redux'

import { useGetLatestScoresQuery } from 'store/score/scoreApi'
import { State } from 'store/state'
import { QueryStatus } from 'store/types'

import { useAppSelector } from '../../../store/hooks'

export const useScoreArea = () => {
  const { apiVersion, sessionId, areas } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
      areas: state.areaReducer.areas || [],
    }),
    shallowEqual
  )
  const { data, isSuccess, isError } = useGetLatestScoresQuery({
    apiVersion,
    sessionId,
  })

  if (isSuccess) {
    const mergedAreas = orderBy(
      data.map(areaScore => ({
        ...areas.find(area => area.id === areaScore.areaId),
        ...areaScore,
      })),
      'title'
    )

    return {
      sub: orderBy(
        mergedAreas.filter(area => area.areaType === 'sub'),
        'priority'
      ),
      local: orderBy(
        mergedAreas.filter(area => area.areaType === 'local'),
        'priority'
      ),
      general: data.filter(area => area.areaType === 'general')[0],
      queryStatus: QueryStatus.SUCCESS,
    }
  }

  return {
    general: undefined,
    sub: [],
    local: [],
    queryStatus: isError ? QueryStatus.ERROR : QueryStatus.LOADING,
  }
}
