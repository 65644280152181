import { theme } from '@fairhq/common'

export const PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
export const DATE_FORMAT = 'Do, MMMM'
export const DOCUMENT_MAX = 1
export const DATE_FORMAT_LIST = 'MMM Do, YYYY'
export const DATE_FORMAT_SESSION = 'MMMM YYYY'
export const DATE_FORMAT_SESSION_URL = 'YYYY-MM-DD-HH-MM'
export const DATE_FORMAT_SURVEY = 'MM / DD / YYYY'
export const PRACTICE_MAX_SHOW = 7
export const CHANGELOG_MAX_SHOW = 5

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: theme.colors.grey500,
      fontFamily: '"Poppins", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: theme.colors.grey300,
      },
      lineHeight: '24px',
      width: '100%',
    },
    invalid: {
      color: theme.colors.red,
      iconColor: theme.colors.red,
    },
  },
}
