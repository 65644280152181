import { useState } from 'react'

import { Flex } from '@theme-ui/components'

import { orderBy, sortBy } from 'lodash'

import { useHistory } from 'react-router'

import { Button, Text } from '@fairhq/common'

import { StateHandler } from 'components/StateHandler'
import { clear as clearInsights } from 'features/reports/store/insightSlice'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { PageTop } from 'layout/PageTop'
import {
  useGetAccountQuery,
  useGetAccountsQuery,
} from 'store/account/accountApiWithQuery'

import { Account } from 'store/account/types'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { clearAccount } from 'store/clear'
import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'
import { companyActions } from 'store/company/companySlice'
import { Company } from 'store/company/types'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { Table } from 'ui-kit/Table'

import { Layout } from '../../layout/Layout'

import { AddCompanyModal } from './AddCompanyModal'
import { AdminListItem } from './AdminListItem'

export type AccountAndCompany = Account & { company: Partial<Company> }

export const Admin = () => {
  const [openAddCompanyModal, setOpenAddCompanyModal] = useState(false)

  const { apiVersion, jwtToken } = useAppSelector((state: State) => ({
    apiVersion: state.apiHeadersReducer.apiVersion,
    jwtToken: state.authReducer.jwt,
  }))

  const { isAdmin } = useAuthRoles()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const {
    data: accounts,
    isSuccess,
    isError,
    isLoading,
  } = useGetAccountsQuery(undefined, { skip: !jwtToken })

  const { refetch: refetchAccount } = useGetAccountQuery({ apiVersion })
  const { refetch: refetchSessions } = useGetSessionsQuery()

  const sortedAccounts: AccountAndCompany[] = sortBy(
    accounts?.map(account => {
      const company = account.companies[0]
      return { ...account, company }
    }),
    ['company.name']
  )

  if (!isAdmin) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  const onClick = (account: AccountAndCompany) => {
    const accountSessions =
      orderBy(account.company.sessions, ['startDate'], ['desc']) ?? []
    dispatch(apiHeadersActions.setAccountId(account.id))
    dispatch(apiHeadersActions.setCompanyId(account.company.id))
    dispatch(apiHeadersActions.setSessionId(accountSessions[0].id))
    dispatch(companyActions.setSessionCurrentlyViewing(accountSessions[0].id))

    dispatch(clearInsights())
    dispatch(clearAccount())
    refetchSessions()
    refetchAccount()

    history.push('/audit')
  }

  return (
    <Layout dark>
      <PageTop>
        <Text element="h1" type="heading" size="xl" fontWeight={500}>
          Select account
        </Text>
        <Button onClick={() => setOpenAddCompanyModal(true)}>
          Add company
        </Button>
      </PageTop>
      <StateHandler
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
      >
        <Flex
          sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 6 }}
        >
          <Table
            sx={{
              bg: 'white',
              th: { bg: 'white', fontWeight: 700, color: 'grey400' },
            }}
            headers={['Company', 'Company ID', 'Account ID']}
            items={sortedAccounts}
            renderItem={account => (
              <AdminListItem
                key={account.company.id}
                account={account}
                onClick={onClick}
              />
            )}
          />
        </Flex>
      </StateHandler>
      {openAddCompanyModal && (
        <AddCompanyModal onClose={() => setOpenAddCompanyModal(false)} />
      )}
    </Layout>
  )
}
