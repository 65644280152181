import { TagTypes, emptySplitApi } from 'emptySplitApi'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { ApiVersionOptions } from 'store/apiHeaders/types'
import { State } from 'store/state'

import { Session } from './types'

// TODO: once all calls in the account reducer are converted to use
// RTK Query, rename this file to companyApi

const companyApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getSessions: build.query<Session[], void>({
      query: () => ({
        url: `/companies/sessions`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
      providesTags: [TagTypes.SESSIONS],
      async onQueryStarted(_, { queryFulfilled, dispatch, getState }) {
        const { data: sessions } = await queryFulfilled

        const { apiHeadersReducer } = getState() as State
        const sessionId = apiHeadersReducer?.sessionId
        if (!sessionId) {
          dispatch(apiHeadersActions.setSessionId(sessions?.[0]?.id))
        }
      },
    }),
    createSession: build.mutation<Session[], void>({
      query: () => ({
        url: `/companies/session`,
        method: 'POST',
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
        invalidatesTags: [TagTypes.SESSIONS],
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        const { data: sessions } = await queryFulfilled
        dispatch(apiHeadersActions.setSessionId(sessions?.[0]?.id))
        dispatch(apiHeadersActions.setApiVersion(sessions?.[0]?.api_version))
      },
    }),
    getApiVersion: build.query<{ version: ApiVersionOptions }, void>({
      query: () => ({
        url: `companies/version`,
        headers: {
          'fairhq-version': ApiVersionOptions.V2,
        },
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch, getState }) {
        const { data: apiVersion } = await queryFulfilled

        const { apiHeadersReducer } = getState() as State
        const storedApiVersion = apiHeadersReducer?.apiVersion
        if (!storedApiVersion) {
          dispatch(apiHeadersActions.setApiVersion(apiVersion.version))
        }
      },
    }),
  }),
})

export const {
  useGetSessionsQuery,
  useCreateSessionMutation,
  useGetApiVersionQuery,
} = companyApi
