import { FunctionComponent, useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'

import { HeaderTitle } from 'components/HeaderTitle/HeaderTitle'
import { useAreaProgress } from 'modules/areas/hooks/useAreaProgress'
import { Area, AreaSource } from 'store/area/types'
import { getStatus as getAuditStatus, getAudit } from 'store/audit/auditSlice'

import { useAppDispatch } from '../../../store/hooks'

export const AreaHeader: FunctionComponent<{
  area?: Area
  group?: AreaSource
}> = ({ area, group = AreaSource.COMPANY }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [redirect, setRedirect] = useState('')
  const { completed, total } = useAreaProgress({
    area,
    group,
  })
  const { parent } = area ?? {}

  const onClose = useCallback(() => {
    if (group === AreaSource.COMPANY && parent?.code) {
      dispatch(getAuditStatus(parent.code))
      if (area?.code) {
        dispatch(getAuditStatus(area.code))
      }
    }
    dispatch(getAudit())
    setRedirect(`/audit/${parent?.code || ''}`)
  }, [area, dispatch, group, parent])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <HeaderTitle
      confirmMessage={t('confirm.areYouSure')}
      onClose={onClose}
      title={area?.title ?? ''}
      completed={completed}
      total={total}
    />
  )
}
