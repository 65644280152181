import { ChangeEventHandler, FunctionComponent } from 'react'

import { SxProp } from 'theme-ui'

import { Select } from 'ui-kit/Select'

interface BenchmarksDropdownProps {
  benchmarks?: string[]
  onChange?: ChangeEventHandler<{ value: string | number }>
  sx?: SxProp
  value?: string
}

export const BenchmarksDropdown: FunctionComponent<BenchmarksDropdownProps> = ({
  onChange,
  benchmarks,
  sx = {},
  value,
}) => {
  if (!benchmarks) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <Select
      onChange={onChange}
      sx={{
        height: 42,
        minWidth: 180,
        marginLeft: '8px',
        ...sx,
      }}
      value={value}
    >
      {benchmarks?.map(item => (
        <option key={item} value={item}>
          {item.split(' - ')[0]}
        </option>
      ))}
    </Select>
  )
}
