import { FunctionComponent, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Container } from 'rsuite'

import styled from 'styled-components'

import { Confirm } from 'components/confirm'

import {
  ProgressContainer,
  Subtitle,
  Title as ContainerTitle,
  Title,
  LinkButton,
} from '../../styled'
import { Message } from '../../styled/message'
import { Progress, BetaBadge } from '../index'

import { ReactComponent as CloseIcon } from './assets/close.svg'
import { ReactComponent as FairHQIcon } from './assets/fairhq.svg'

const HeaderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  padding: 24px 0 30px;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 200;

  h2 {
    font-size: 18px;
    line-height: 1.67;
  }

  ${Subtitle} {
    line-height: 1.37;
  }

  ${ContainerTitle} {
    display: flex;
    flex-direction: column;
    line-height: 2.25;
    padding: 0 20px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 32px;
      background: ${({ theme }) => theme.colors.grey200};
    }
  }

  .rs-container {
    padding-left: 30px;
    margin: 0;
  }

  .rs-btn {
    margin-right: 100px;
    padding: 0;

    svg {
      margin-left: 12px;
    }
  }

  ${ProgressContainer} {
    padding-left: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

interface HeaderTitleProps {
  confirmMessage: string
  title: string
  completed?: number
  onClose?: () => void
  total?: number
}

export const HeaderTitle: FunctionComponent<HeaderTitleProps> = ({
  confirmMessage,
  onClose,
  title,
  completed = 0,
  total = 0,
}) => {
  const { t } = useTranslation()
  const [confirmVisible, setConfirmVisible] = useState(false)
  return (
    <HeaderTitleContainer>
      {onClose ? (
        <Confirm
          title={<h3>{t('confirm.wannaTakeABreak')}</h3>}
          confirmLabel={t('confirm.leaveAndSave')}
          cancelLabel={t('confirm.continue')}
          onCancel={() => setConfirmVisible(false)}
          onSubmit={() => {
            onClose && onClose()
            setConfirmVisible(false)
          }}
          visible={confirmVisible}
        >
          <Message>{confirmMessage}</Message>
        </Confirm>
      ) : undefined}
      <Title>
        <FairHQIcon />
        <BetaBadge />
      </Title>
      <Container>
        <h2>{title}</h2>
      </Container>
      {onClose ? (
        <LinkButton onClick={() => setConfirmVisible(true)}>
          <span>{t('header.close')}</span>
          <CloseIcon />
        </LinkButton>
      ) : undefined}
      <Progress
        progress={(completed && total && (completed * 100) / total) || 0}
        mode="header"
      />
    </HeaderTitleContainer>
  )
}
