import React, { Dispatch, useEffect, useState } from 'react'

import { useAuth0, User } from '@auth0/auth0-react'
import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { IntercomProps, useIntercom } from 'react-use-intercom'
import { Checkbox, Schema, SelectPicker } from 'rsuite'

import { Box } from 'theme-ui'

import { Fields } from 'components/form/Fields'
import { Form } from 'components/form/Form'
import { useErrorMessage } from 'hooks/useErrorMessage'
import { useSubmit } from 'hooks/useSubmit'
import { updateOnboarding } from 'store/account/accountSlice'
import { authActions } from 'store/auth/authSlice'
import { State } from 'store/state'
import { convertToIntercomUser } from 'utils/convertToIntercomUser'
import { rolesOptions } from 'utils/rolesOptions'
import { tCode } from 'utils/tCode'

import { useSchema } from '../../../hooks'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { Message } from '../../../styled/message'

const buildUserFormValues = (values?: User) => {
  const { given_name, family_name, user_metadata } = values ?? {}
  return {
    firstName: given_name || '',
    lastName: family_name || '',
    role: user_metadata?.role || '',
    role_other: user_metadata?.role_other || '',
  }
}

const endOnboarding = (
  dispatch: Dispatch<any>,
  user_metadata: any,
  updateIntercom: (props?: Partial<IntercomProps> | undefined) => void
) =>
  dispatch(
    updateOnboarding({
      user: {
        user_metadata: {
          ...user_metadata,
          onboarding: { ...user_metadata?.onboarding, hidden: true },
        },
      },
      refreshUser: true,
    })
  )
    // Redux Toolkit isn't great at typing what is returned from createAsyncThunk
    // and thinks we're returning nothing. Instructions on how to change that really aren't clear
    // @ts-ignore
    .unwrap()
    .then((user: User) => {
      updateIntercom(convertToIntercomUser(user))
    })

const DetailsFormComponent = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { update: updateIntercom } = useIntercom()
  const { getAccessTokenSilently } = useAuth0()

  const { isGoogleAuth, error, user, saved, isInvited, user_metadata } =
    useAppSelector(
      (state: State) => ({
        isGoogleAuth:
          state.authReducer.user?.sub?.includes('google') ||
          state.authReducer.user?.user_id?.includes('google'),
        error: state.accountReducer.error,
        user: state.authReducer.user,
        saved: state.accountReducer.saved,
        isInvited: state.authReducer.user?.user_metadata?.invited,
        user_metadata: state.authReducer.user?.user_metadata,
      }),
      shallowEqual
    )

  const [redirect, setRedirect] = useState(
    user_metadata?.onboarding?.hidden ? '/' : ''
  )
  const [submittingEndOnboarding, setSubmittingEndOnboarding] = useState(false)

  const previousUser = usePrevious(user)
  const { errorMessage, setError } = useErrorMessage()
  const [consent, setConsent] = useState(!!user_metadata?.consent)
  const [form, setForm] = useState(buildUserFormValues(user))
  const { setSubmitting, submitting, onSubmit } = useSubmit(() => {
    if (isEqual(buildUserFormValues(user), form)) {
      endOnboarding(dispatch, user_metadata, updateIntercom)
      setSubmittingEndOnboarding(true)

      return
    }

    let data: User = {
      user_metadata: {
        ...user?.user_metadata,
        role: form.role as string,
        role_other: form?.role !== 'other' ? null : form?.role_other,
        consent: true,
      },
    }

    if (!isGoogleAuth) {
      data = {
        ...data,
        given_name: `${form.firstName}`,
        family_name: `${form.lastName}`,
        nickname: `${form.firstName}`,
        name: `${form.firstName} ${form.lastName}`,
      }
    }

    dispatch(updateOnboarding({ user: data }))
      // Redux Toolkit isn't great at typing what is returned from createAsyncThunk
      // and thinks we're returning nothing. Instructions on how to change that really
      // aren't clear
      // @ts-ignore
      .unwrap()
      .then((user: User) => {
        updateIntercom(convertToIntercomUser(user))
      })
  })
  const { firstName, lastName, role, role_other } = useSchema()
  const model = Schema.Model({
    firstName,
    lastName,
    role: role.isRequired(t('form.required')),
    role_other,
  })
  const fields = [
    { name: 'firstName', props: { disabled: !!isGoogleAuth } },
    { name: 'lastName', props: { disabled: !!isGoogleAuth } },
    {
      name: 'role',
      accepter: SelectPicker,
      props: {
        data: rolesOptions.map(({ value, label }) => ({
          value,
          label: tCode(t)(label, '', label),
        })),
        preventOverflow: true,
        style: form?.role === 'other' ? { marginBottom: 0 } : undefined,
      },
    },
    {
      name: 'role_other',
      hidden: form?.role !== 'other',
      hiddenLabel: true,
      props: {
        className: 'form-other',
      },
    },
  ]

  useEffect(() => {
    if ((submitting && saved) || (saved && !isEqual(previousUser, user))) {
      endOnboarding(dispatch, user_metadata, updateIntercom)
      setSubmittingEndOnboarding(true)
      setSubmitting(false)
    }
  }, [
    isInvited,
    previousUser,
    saved,
    setRedirect,
    setSubmitting,
    submitting,
    user,
    dispatch,
    user_metadata,
    updateIntercom,
  ])

  useEffect(() => {
    if (saved && submittingEndOnboarding) {
      setSubmittingEndOnboarding(false)
      getAccessTokenSilently().then(token => {
        if (token) {
          dispatch(authActions.setJWT(token))
        }
        setRedirect('/')
      })
    }
  }, [dispatch, getAccessTokenSilently, saved, submittingEndOnboarding])

  useEffect(() => {
    if (error) {
      setError(error)
    }
  }, [error, setError])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Form
      model={model}
      formValue={form}
      loading={submitting}
      disabled={!consent}
      submitBtnLabel="Submit"
      onChange={(formValue: typeof form) => setForm(formValue)}
      onSubmit={onSubmit}
      errorMessage={errorMessage}
      checkTrigger="none"
      withoutContainer
    >
      <Message>{t('hello.personalInfo.accountCreated')}</Message>
      <Fields data={fields} />
      <Box sx={{ marginTop: '30px' }}>
        <Checkbox
          checked={consent}
          onChange={(value, checked) => setConsent(checked)}
        >
          <span>{t('dvc.details.consent')}</span>
        </Checkbox>
      </Box>
    </Form>
  )
}

export const DetailsForm = React.memo(DetailsFormComponent)
