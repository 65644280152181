import React, { FunctionComponent } from 'react'

import { Grid } from '@theme-ui/components'

import { SubNavItem } from 'features/navigation/SubNavItem'
import useSignOut from 'hooks/use-sign-out'

import RouterLink from 'ui-kit/RouterLink'

export const AccountMenu: FunctionComponent = () => {
  const signOut = useSignOut()

  return (
    <Grid
      gap={3}
      sx={{
        variant: 'text.body',
        position: 'absolute',
        top: '100%',
        right: 0,
        whiteSpace: 'nowrap',
        width: 150,
        mt: 2,
        mr: -1,
        px: 2,
        py: 2,
        bg: 'white',
        borderRadius: 12,
        boxShadow: '0px 3px 8px rgba(10, 27, 64, 0.15)',
      }}
    >
      <RouterLink to="/settings/account">
        <SubNavItem label="nav.settings.account" icon="customer" />
      </RouterLink>

      <SubNavItem label="nav.signOut" icon="logOut" onClick={signOut} />
    </Grid>
  )
}
