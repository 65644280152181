import { FunctionComponent } from 'react'

import { Container } from 'rsuite'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  margin-top: 12px;
  width: 100%;
`
export interface TextareaProps {
  onChange: (value: string) => void
  value: string | undefined
  placeholder?: string
}

export const Textarea: FunctionComponent<TextareaProps> = ({
  value,
  placeholder,
  onChange,
}) => (
  <Container>
    <StyledTextArea
      onChange={e => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      rows={5}
    />
  </Container>
)
