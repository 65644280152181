import { Price } from 'store/payment/types'

export function toAmount({
  price,
  discount,
  language,
  size,
  tax = 0,
  coupon,
}: {
  price?: Price
  discount?: number
  language: string
  tax?: number
  size?: number
  coupon?: any
}) {
  if (price?.billing_scheme === 'tiered') {
    const tieredAmount = price?.tiers?.reduce(
      (acc, tier) => {
        if (acc.size && acc.size > 0) {
          if (tier?.up_to && acc.size > tier?.up_to) {
            acc.total += tier.up_to * (tier.unit_amount / 100)
            acc.size -= tier.up_to
          } else {
            acc.total += acc.size * (tier.unit_amount / 100)
            acc.size = 0
          }
        }
        return acc
      },
      { total: 0, size }
    )
    return toCurrency({
      number:
        (coupon
          ? ((100 - coupon.percent_off) / 100) * (tieredAmount?.total ?? 0)
          : tieredAmount?.total ?? 0) *
        (1 + tax),
      language,
      currency: price.currency,
    })
  }
  return price
    ? toCurrency({
        number:
          (discount !== undefined ? discount : price.unit_amount / 100) *
          (1 + tax),
        language,
        currency: price.currency,
      })
    : ''
}

export function toSymbol(currency = 'GBP') {
  const symbols: Record<string, string> = { GBP: '£', EUR: '€', USD: '$' }
  return symbols[currency] || currency
}

export function toCurrency({
  number,
  language,
  currency = 'GBP',
}: {
  number: number
  currency?: string
  language: string
}) {
  return new Intl.NumberFormat([language, 'en'], {
    style: 'currency',
    currency,
  }).format(number)
}
