import { FunctionComponent } from 'react'

import { Flex, Text, Image } from '@theme-ui/components'

import { makeTransition } from '@fairhq/common'

import { useAreaProgress } from 'modules/areas/hooks/useAreaProgress'

import Icon from '../../../ui-kit/Icon'
import RouterLink from '../../../ui-kit/RouterLink'

const AreaCard: FunctionComponent<{
  label: string
  code: string
  image: string
}> = ({ label, code, image }) => {
  const { completed, total } = useAreaProgress({ area: { code } })

  return (
    <RouterLink to={`/audit/${code}`}>
      <Flex
        sx={{
          bg: 'white',
          borderRadius: 18,
          height: 110,
          px: 4,
          py: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'grey500',
          transition: makeTransition('color'),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        <Image
          src={image}
          alt=""
          sx={{ height: 72, width: 72, mr: 3, flex: '0 0 auto' }}
        />
        <Text as="div" variant="bodyBold" sx={{ mr: 4, flex: '1 1 auto' }}>
          {label}
        </Text>
        <Text
          as="div"
          variant="bodyBold"
          sx={{ color: 'grey300', flex: '0 0 auto', textAlign: 'right' }}
        >
          <Text sx={{ color: 'grey400' }}>{completed}</Text>/{total}
          {completed >= total && (
            <Icon
              name="checkboxCheckedRounded"
              sx={{ ml: 1, mt: '-1px', fill: 'green' }}
            />
          )}
        </Text>
      </Flex>
    </RouterLink>
  )
}

export default AreaCard
