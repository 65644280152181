import { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { space, makeTransition } from '@fairhq/common'
import { useGetInsightAreasQuery } from 'features/reports/store/insightsApiWithQuery'
import { useAreaProgress } from 'modules/areas/hooks/useAreaProgress'
import { useTitle } from 'modules/areas/hooks/useTitle'
import { State } from 'store/state'

import Sidebar from '../../layout/Sidebar'
import { useAppSelector } from '../../store/hooks'
import Icon from '../../ui-kit/Icon'
import RouterLink from '../../ui-kit/RouterLink'

const GroupHeader: FunctionComponent<{
  text: string
  url: string
  completion?: string
}> = ({ text, url, completion }) => {
  const { pathname } = useLocation()
  const isActive = pathname.endsWith(url)

  return (
    <RouterLink to={url}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 54,
          pl: `${space[7] - 3}px`,
          pr: 4,
          py: 2,
          color: isActive ? 'purple' : 'grey500',
          borderLeft: '3px solid',
          bg: isActive ? 'grey100' : 'transparent',
          borderColor: isActive ? 'purple' : 'transparent',
          transition: [
            makeTransition('background'),
            makeTransition('border'),
            makeTransition('color'),
          ].join(),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        <Text as="div" variant="bodyBold">
          {text}
        </Text>
        {completion ? (
          <Text
            as="div"
            sx={{ color: completion === '0%' ? 'grey300' : 'grey400' }}
          >
            {completion}
          </Text>
        ) : null}
      </Flex>
    </RouterLink>
  )
}

const MenuItem: FunctionComponent<{
  text: string
  url: string
  code?: string
  completion?: string
}> = ({ text, url, code, completion }) => {
  const { pathname } = useLocation()
  const isActive = pathname.startsWith(url)
  const { completed, total } = useAreaProgress({ area: { code } })

  const percent = Math.round((completed * 100) / total)

  const progress = completion || percent || 0

  return (
    <RouterLink to={url}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 48,
          pl: `${space[8] - 3}px`,
          pr: 4,
          py: 1,
          color: isActive ? 'purple' : 'grey500',
          borderLeft: '3px solid',
          bg: isActive ? 'grey100' : 'transparent',
          borderColor: isActive ? 'purple' : 'transparent',
          transition: [
            makeTransition('background'),
            makeTransition('border'),
            makeTransition('color'),
          ].join(),
          '&:hover': {
            color: 'purple',
          },
        }}
      >
        <Text as="div" variant="label">
          {text}
        </Text>
        {progress >= 0 ? (
          progress === 100 ? (
            <Icon name="checkboxCheckedRounded" sx={{ fill: 'green' }} />
          ) : (
            <Text as="div" sx={{ color: 'grey400' }}>
              {progress}%
            </Text>
          )
        ) : null}
      </Flex>
    </RouterLink>
  )
}

export const AssessmentSidebar: FunctionComponent = () => {
  const { apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
    }),
    shallowEqual
  )
  const { data: areas } = useGetInsightAreasQuery({ apiVersion, sessionId })

  const { percent } = useTitle()

  return (
    <Sidebar sx={{ py: 6 }}>
      <GroupHeader text="Audit" url="/audit" completion={`${percent}%`} />
      {(areas ?? [])
        .filter(({ type }) => type === 'sub')
        .map(({ code, title: areaTitle }) => (
          <MenuItem
            key={code}
            text={areaTitle ?? ''}
            url={`/audit/${code}`}
            code={code}
          />
        ))}
    </Sidebar>
  )
}
