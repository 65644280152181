import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isRejected } from 'store/helpers/isRejected'

import { QueryStatus } from 'store/types'

import { Changelog } from '../../features/reports/store/types'

import { auditApi } from './auditApi'
import { AuditState } from './types'

const clear = createAction('audit/clear')

const retrieveAudit = async (getState: () => unknown, headers?: any) => {
  try {
    const [companyResponse] = await auditApi.getAudit(getState, headers)

    return [await companyResponse.json()]
  } catch (error) {
    const typedError = error as NotifiableError
    Bugsnag.notify(typedError)
    throw error
  }
}

export const getAudit = createAsyncThunk(
  'audit/getAudit',
  async (_, { getState }) => retrieveAudit(getState)
)

export const getSessionAudit = createAsyncThunk(
  'audit/getSessionAudit',
  async (
    changelog: Omit<Changelog, 'compareToVersion' | 'compareTo'>,
    { getState }
  ) => [
    ...(await retrieveAudit(getState, {
      'fairhq-version': changelog.compareFromVersion,
      'fairhq-session': changelog.compareFrom,
    })),
    changelog.compareFrom,
  ]
)

export const getStatus = createAsyncThunk(
  'audit/getStatus',
  async (code: string, { getState }) => {
    const response = await waitForResponse({
      callback: () => auditApi.status(getState, code),
    })
    return { code, status: response }
  }
)

const initialState: Partial<AuditState> = {
  areas: {},
  session: {},
  auditQueryStatus: QueryStatus.IDLE,
  sessionAuditQueryStatus: QueryStatus.IDLE,
}

const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(getAudit.pending, state => {
        state.auditQueryStatus = QueryStatus.LOADING
      })
      .addCase(getAudit.fulfilled, (state, action) => {
        state.auditQueryStatus = QueryStatus.SUCCESS
        const [company] = action.payload ?? []
        state.status = { company }
      })
      .addCase(getAudit.rejected, state => {
        state.auditQueryStatus = QueryStatus.ERROR
      })
      .addCase(getSessionAudit.pending, state => {
        state.sessionAuditQueryStatus = QueryStatus.LOADING
      })
      .addCase(getSessionAudit.fulfilled, (state, action) => {
        state.sessionAuditQueryStatus = QueryStatus.SUCCESS
        const [company, session] = action.payload ?? []
        // Condition only needed to make TypeScript happy (because state is a Partial of the AuditState)
        // In reality, session starts out as an empty object so this condition will always be true
        if (state.session) {
          state.session[session] = { company }
        }
      })
      .addCase(getSessionAudit.rejected, state => {
        state.sessionAuditQueryStatus = QueryStatus.ERROR
      })
      .addCase(getStatus.pending, (state, action) => {
        if (state.areas) {
          state.areas[action.meta.arg] = {
            ...state.areas[action.meta.arg],
            loading: true,
          }
        }
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        const { code, status } = action.payload
        if (state.areas) {
          state.areas[code] = status
        }
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isRejected('audit'), handleErrorState)
      .addMatcher(isClearAll(), () => initialState)
  },
})

export const { actions: auditActions, reducer: auditReducer } = auditSlice
