import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'

import { insightApi } from './insightApi'
import { InsightState, Changelog } from './types'

export const clear = createAction('insights/clear')

export const getBenchmarkOptionsByGroup = createAsyncThunk(
  'insights/getBenchmarkOptionsByGroup',
  async (group: string, { getState }) => {
    const result = await waitForResponse({
      callback: () => insightApi.getBenchmarkOptionsByGroup(getState, group),
    })

    return { group, benchmarks: result }
  }
)

export const getChangelog = createAsyncThunk(
  'insights/getChangelog',
  async (changelog: Changelog, { getState }) => {
    const result = await waitForResponse({
      callback: () => insightApi.getChangelog(getState, changelog),
    })

    return {
      result,
      name: `${changelog?.compareFrom}_${changelog?.compareTo}`,
    }
  }
)

const initialState: Partial<InsightState> = {
  benchmarks: {},
  changelog: {},
}

const insightSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(getBenchmarkOptionsByGroup.fulfilled, (state, action) => {
        const { group, benchmarks } = action.payload
        if (state.benchmarks) {
          state.benchmarks[group] = benchmarks
        }
      })
      .addCase(getChangelog.fulfilled, (state, action) => {
        if (state.changelog) {
          state.changelog[action.payload.name] = action.payload.result
        }
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
  },
})

export const { actions: insightsActions, reducer: insightsReducer } =
  insightSlice
