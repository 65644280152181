import React, { FunctionComponent } from 'react'

import { Flex } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'

import { GraphBenchmark } from './types'

interface GraphContainerProps {
  isHorizontal: boolean
  barHeight: number
  benchmark?: GraphBenchmark['value']
  sx: ThemeUIStyleObject
  children: React.ReactNode
}

const DEFAULT_HALFLINE_POSITION = 0.5

export const GraphContainer: FunctionComponent<GraphContainerProps> = ({
  isHorizontal,
  barHeight,
  benchmark,
  sx,
  children,
}) => {
  const commonPseudo = {
    content: '""',
    position: 'absolute',
    bg: 'border',
    zIndex: -1,
  }

  const verticalStyles = {
    borderTop: '1px solid',

    '&:before, &:after': {
      ...commonPseudo,

      left: 0,
      width: '100%',
      height: 1,
    },
    ':before': {
      height: benchmark ? 2 : 1,
      top: (1 - (benchmark || DEFAULT_HALFLINE_POSITION)) * barHeight,
      bg: benchmark ? 'transparent' : '',
      backgroundImage: benchmark
        ? t =>
            `linear-gradient(to left, ${t.colors?.grey200} 25%, transparent 25%, transparent 75%, ${t.colors?.grey200} 75%)`
        : '',
      backgroundSize: '24px 2px',
    },
    '&:after': {
      top: barHeight,
    },
  } as ThemeUIStyleObject

  const horizontalStyles = {
    borderRight: '1px solid',

    '&:before, &:after': {
      ...commonPseudo,
      left: 'calc(50% + 160px)',
      top: 0,
      width: 1,
      height: '100%',
    },
    '&:after': {
      left: 320,
    },
  }

  return (
    <Flex
      // @ts-ignore
      sx={{
        position: 'relative',
        zIndex: 1,
        justifyContent: 'space-around',
        flexDirection: isHorizontal ? 'column' : 'row',

        ...(isHorizontal ? horizontalStyles : verticalStyles),
        borderColor: 'border',
        gap: '18px',
        ...sx,
      }}
    >
      {children}
    </Flex>
  )
}
