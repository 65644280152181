import { createSlice } from '@reduxjs/toolkit'
import { sortBy, isArray } from 'lodash'

import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'

import { getQuestionWithSortedOptions } from './helpers/getQuestionWithSortedOptions'

import { QuestionSessionState } from './types'

const initialState: QuestionSessionState = {
  other: '',
  selectedOptions: [],
  value: '',
  currentIndex: 0,
  currentAllIndex: 0,
  countAnswer: 0,
  hasNext: true,
  questions: [],
  savedAssessments: [],
}

const updateAssessment = (state: QuestionSessionState) => {
  const savedAssessment = state.savedAssessments?.find(
    assessment => assessment?.question?.id === state?.question?.id
  )
  state.assessment = savedAssessment
  const {
    other = '',
    value = '',
    assessmentOptions,
    question: assessmentQuestion,
  } = state.assessment ?? {}
  state.other = other
  state.value = value
  state.selectedOption =
    (assessmentQuestion?.type !== 'multi' &&
      assessmentOptions?.[0]?.option?.id) ||
    undefined

  const newValues =
    (assessmentQuestion?.type === 'multi' &&
      (assessmentOptions || [])
        ?.filter(({ option }) => option?.id)
        .map(({ option }) => option?.id)) ||
    []
  state.selectedOptions = isArray(newValues) ? newValues : []
}

const next = (state: QuestionSessionState) => {
  const stop = () => {
    state.hasNext = false
  }
  state.currentIndex += 1
  if (state.currentIndex <= state.questions.length - 1) {
    const nextQuestion = state.questions[state.currentIndex]

    state.question = getQuestionWithSortedOptions(nextQuestion)
    updateAssessment(state)
  } else {
    stop()
  }
}

const questionSessionSlice = createSlice({
  name: 'question-session',
  initialState,
  reducers: {
    clear: () => initialState,
    saveAssessments(state, action) {
      if (action?.payload?.length > 0) {
        state.savedAssessments = action.payload
        updateAssessment(state)
      }
    },
    addAssessment(state, action) {
      state.savedAssessments = [
        ...(state.savedAssessments || []).filter(
          ({ id }: any) => +id !== +action.payload.id
        ),
        action.payload,
      ]
      state.assessment = action.payload
    },
    next,
    previous(state) {
      if (state.currentIndex > 0) {
        state.currentIndex -= 1
        const previousQuestion = state.questions[state.currentAllIndex]
        state.question = getQuestionWithSortedOptions(previousQuestion)
        updateAssessment(state)
        state.hasNext = true
      }
    },
    setQuestionsAfterGet(state, action) {
      state.questions = sortBy(action.payload, ['priority'])
      const [firstQuestion] = state.questions
      if (firstQuestion) {
        state.question = getQuestionWithSortedOptions(firstQuestion)
      }
    },
    setOther(state, action) {
      state.other = action.payload
    },
    setValue(state, action) {
      state.value = action.payload
    },
    setSelectedOption(state, action) {
      state.selectedOption = Number(action.payload)
    },
    setSelectedOptions(state, action) {
      if (action.payload) {
        state.selectedOptions = action.payload
      }
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(isClearAll(), () => initialState)
      .addMatcher(isClearAccount(), () => initialState)
  },
})

export const {
  actions: questionSessionActions,
  reducer: questionSessionReducer,
} = questionSessionSlice
