import _ from 'lodash'
import { shallowEqual } from 'react-redux'

import { ApiVersion, SessionId } from 'store/apiHeaders/types'
import { useGetAuditCompanyQuery } from 'store/audit/auditApiWithQuery'

import { AuditStatus } from 'store/audit/types'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'

import { QueryStatus } from 'store/types'

export const useAuditBy = (
  sessionId?: SessionId,
  apiVersion?: ApiVersion
): {
  audit: AuditStatus | undefined
  percentage: number
  completed: boolean | undefined
  auditQueryStatus: QueryStatus | undefined
  assessmentPercentage: number
  isCompanyAssessmentCompleted?: boolean
  isCompanyAssessmentStarted?: boolean
} => {
  const { apiHeadersSessionId, apiHeadersApiVersion } = useAppSelector(
    (state: State) => ({
      apiHeadersSessionId: state.apiHeadersReducer.sessionId,
      apiHeadersApiVersion: state.apiHeadersReducer.apiVersion,
    }),
    shallowEqual
  )

  const values = {
    sessionId: sessionId ?? apiHeadersSessionId,
    apiVersion: apiVersion ?? apiHeadersApiVersion,
  }

  const {
    data: company,
    isLoading: isCompanyLoading,
    isError: isCompanyError,
  } = useGetAuditCompanyQuery({
    sessionId: values.sessionId,
    apiVersion: values.apiVersion,
  })

  const percentage = company ? Math.round(_.mean([company.percent])) : 0

  const assessmentPercentage = company
    ? Math.round(_.mean([company.percent]))
    : 0

  const completed = assessmentPercentage === 100

  if (isCompanyError) {
    return {
      auditQueryStatus: QueryStatus.ERROR,
      percentage,
      completed: undefined,
      audit: undefined,
      assessmentPercentage,
      isCompanyAssessmentCompleted: false,
    }
  }

  if (isCompanyLoading) {
    return {
      auditQueryStatus: QueryStatus.LOADING,
      percentage,
      completed: undefined,
      audit: undefined,
      assessmentPercentage,
      isCompanyAssessmentCompleted: false,
    }
  }

  return {
    auditQueryStatus: QueryStatus.SUCCESS,
    percentage,
    completed,
    assessmentPercentage,
    audit: company ? { company } : undefined,
    isCompanyAssessmentCompleted: completed,
    isCompanyAssessmentStarted:
      assessmentPercentage > 0 && assessmentPercentage < 100,
  }
}
