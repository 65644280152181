import { FunctionComponent, useEffect } from 'react'

import { Flex, Heading } from '@theme-ui/components'

import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'

import { Link } from 'react-router-dom'

import { ReactComponent as StrategyBlankslate } from 'assets/illustrations/strategy-blankslate.svg'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { Layout } from 'layout/Layout'
import { getAreas } from 'store/area/areaSlice'
import { AreaType } from 'store/area/types'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useGetLatestScoresQuery } from 'store/score/scoreApi'
import { State } from 'store/state'
import Blankslate from 'ui-kit/Blankslate'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { ReportsWithScores } from './ReportsWithScores'
import { EmptySidebar } from './sidebar/EmptySidebar'
import { ReportsSidebar } from './sidebar/ReportsSidebar'

export const ReportsLandingPage: FunctionComponent = () => {
  const { isAdmin } = useAuthRoles()

  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { apiVersion, sessionId, viewingCompanyId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
      viewingCompanyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )

  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })
  const { refetch: refetchLatestScores } = useGetLatestScoresQuery({
    apiVersion,
    sessionId,
  })

  useEffect(() => {
    if (isScorable) {
      dispatch(getAreas({ type: AreaType.SUB }))
      refetchLatestScores()
    }
  }, [refetchLatestScores, isScorable, dispatch])

  if (!viewingCompanyId && isAdmin) {
    return (
      <Layout>
        <p>Pelase select a company first</p>
        <Link to="/admin">Take me there</Link>
      </Layout>
    )
  }

  return (
    <Layout
      sidebar={!isAdmin && !isScorable ? <EmptySidebar /> : <ReportsSidebar />}
      dark
    >
      <Flex
        sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 6 }}
      >
        <Heading mr={4}>{t('nav.reports')}</Heading>
      </Flex>
      {isScorable ? (
        <ReportsWithScores />
      ) : (
        <Blankslate
          titleKey="reports.blankslate.title"
          textKey="reports.blankslate.description"
          illustComp={StrategyBlankslate}
          buttonText="reports.blankslate.button"
        />
      )}
    </Layout>
  )
}
