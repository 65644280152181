import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'

import { makeTransition } from '@fairhq/common'

import { GraphItem, GraphTooltipFormatter } from './types'

export const DEFAULT_TOOLTIP_FORMATTER: GraphTooltipFormatter = ({
  max,
  value,
}) => {
  if (!value) {
    return null
  }
  return `${Math.abs(value)} / ${max}`
}

interface GraphTooltipProps {
  item: GraphItem
  tooltipFormatter: GraphTooltipFormatter
  isValueLabelMoved: boolean
  isNegative: boolean
  isHorizontal: boolean
}

export const GraphTooltip: FunctionComponent<GraphTooltipProps> = ({
  item,
  tooltipFormatter,
  isValueLabelMoved,
  isNegative,
  isHorizontal,
}) => {
  const verticalStyles = {
    bottom: '100%',
    left: '50%',
    mb: isValueLabelMoved ? 3 : 7,
    transform: 'translate(-50%, 6px)',
  }
  const verticalAfterStyles = {
    top: '100%',
    left: '50%',
    ml: -1,
    borderWidth: '6px 6px 0 6px',
    borderTopColor: 'grey500',
  }

  const horizontalStyles = {
    bottom: '50%',
    left: '100%',
    ml: 3,
    transform: 'translate(-6px, 50%)',
  }
  const horizontalAfterStyles = {
    top: '50%',
    right: '100%',
    mt: -1,
    borderWidth: '6px 6px 6px 0',
    borderRightColor: 'grey500',
  }

  return (
    <Box
      className="Graph__tooltip"
      sx={{
        position: 'absolute',
        py: 1,
        px: 2,
        bg: 'grey500',
        opacity: 0,
        pointerEvents: 'none',
        whiteSpace: 'nowrap',
        borderRadius: 6,
        transition: [
          makeTransition('opacity'),
          makeTransition('transform'),
        ].join(),
        '&:after': {
          content: '""',
          position: 'absolute',
          borderStyle: 'solid',
          borderColor: 'transparent',
          ...(isHorizontal ? horizontalAfterStyles : verticalAfterStyles),
        },
        ...(isHorizontal ? horizontalStyles : verticalStyles),
      }}
    >
      <Text
        as="div"
        variant="label"
        sx={{
          color: 'white',
          transform: isNegative ? 'rotate(-180deg)' : '',
        }}
      >
        {tooltipFormatter(item)}
      </Text>
    </Box>
  )
}
