import { http } from 'utils/http'

import { PaymentInfo, RetryInvoiceInfo } from './types'

export const paymentApi = {
  getPrices(getState: () => unknown) {
    return http.get(getState, 'payment/prices')
  },
  getTax(getState: () => unknown) {
    return http.get(getState, 'payment/taxes')
  },
  getCoupon(getState: () => unknown, coupon: string) {
    return http.get(getState, `payment/coupon/${coupon}`)
  },
  processPayment(getState: () => unknown, info: PaymentInfo) {
    return http.post(getState, 'payment', info)
  },
  retryInvoice(getState: () => unknown, info: RetryInvoiceInfo) {
    return http.post(getState, 'payment/retry', info)
  },
  getInvoices(getState: () => unknown) {
    return http.get(getState, 'payment/invoices')
  },
}
