import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Flex, Text } from 'theme-ui'

import { baseColors, makeTransition, space } from '@fairhq/common'
import { Assessment } from 'components/svgs/icons/Assessment'
import { Documents } from 'components/svgs/icons/Documents'
import { useAuthRoles } from 'hooks/useAuthRoles'
import RouterLink from 'ui-kit/RouterLink'

const IconContainer = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? baseColors.purple : baseColors.grey300};
  margin-right: ${space[2]}px;
  display: flex;
  align-items: center;
`

interface NavItem {
  icon?: FunctionComponent
  key?: string
  label: string
  to: string
}

export const NavItems: FunctionComponent = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation()
  const { isAdmin } = useAuthRoles()

  const navItems: NavItem[] = [
    {
      label: t('nav.reports'),
      key: 'reports',
      to: '/reports',
      icon: Assessment,
    },
  ]

  if (isAdmin) {
    navItems.unshift(
      { label: t('nav.admin'), key: 'admin', to: '/admin' },
      {
        label: t('nav.assessment'),
        key: 'audit',
        to: '/audit',
        icon: Documents,
      }
    )
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {navItems?.map(({ to, icon: Icon, label, key }) => {
        const isActive = pathname.startsWith(to)
        return (
          <RouterLink to={to} key={key}>
            <Flex
              sx={{
                alignItems: 'center',
                px: 2,
                mx: 2,
                height: '100%',
                borderBottom: '3px solid',
                borderColor: isActive ? 'purple' : 'transparent',
                color: isActive ? 'purple' : 'grey500',
                '&:hover': {
                  color: 'purple',
                },
              }}
            >
              {Icon ? (
                <IconContainer isActive={isActive}>
                  <Icon />
                </IconContainer>
              ) : null}
              <Text
                as="div"
                variant="labelBold"
                sx={{ color: 'inherit', transition: makeTransition('color') }}
              >
                {label}
              </Text>
            </Flex>
          </RouterLink>
        )
      })}
    </>
  )
}
