import { Assessment } from '../assessment/types'
import { Question } from '../questionTypes'

export enum AreaSource {
  EMPLOYEE = 'employee',
  BENCHMARK = 'benchmark',
  COMPANY = 'company',
  DOCUMENTS = 'documents',
}

export enum AreaType {
  LOCAL = 'local',
  SUB = 'sub',
  GENERAL = 'general',
}

export interface AreasQueryParams {
  code?: string
  source?: AreaSource
  type?: AreaType
  parentAreaCode?: string
}

export interface Area {
  id: number
  title: string
  description: string
  airtable_id: string
  code: string
  type: string
  area_airtable_id: string
  priority: number
  sources: string[]
  parent: Area
  [key: string]: any
}

export interface AreaState {
  error: any
  loading: boolean
  assessmentsLoading: boolean
  documentTypesLoading: boolean
  questionsLoading: boolean
  areas: Area[]
  area: Area
  questions: Record<string, Question[]>
  assessments: Record<string, { assessments: Assessment[]; loaded: boolean }>
  query: AreasQueryParams
  status: any
}
