import { http } from 'utils/http'

export const auditApi = {
  getAudit(getState: () => unknown, headers: any) {
    return Promise.all([http.get(getState, `audit/company`, headers)])
  },
  status(getState: () => unknown, code = '') {
    return http.get(getState, `areas/${code}/status`)
  },
}
