import React, { FunctionComponent } from 'react'

import { Box, Text } from '@theme-ui/components'
import { sumBy } from 'lodash'

import { makeTransition, Colors } from '@fairhq/common'

import { GraphItem, ColorGetterFn } from './types'

const getBarTransform = (
  isHorizontal: boolean,
  isNegative: boolean
): string => {
  if (isHorizontal) {
    return isNegative ? 'rotate(180deg)' : ''
  }

  return isNegative ? 'translateX(-50%) rotate(180deg)' : 'translateX(-50%)'
}

interface GraphBarProps {
  item: GraphItem
  barHeight: number
  barSize: number
  isHorizontal: boolean
  isSplit: boolean
  isNegative?: boolean
  colorGetter?: ColorGetterFn
  children?: React.ReactNode
}

export const GraphBar: FunctionComponent<GraphBarProps> = ({
  item,
  barHeight,
  barSize,
  isHorizontal,
  isSplit,
  isNegative = false,
  colorGetter,
  children,
}) => (
  <Box
    sx={{
      position: 'relative',
      height: isHorizontal ? 42 : barHeight,
      flex: isHorizontal ? '1 1 auto' : '0 0 auto',
      mb: isHorizontal ? 0 : 2,
      order: isHorizontal ? 2 : 1,
    }}
  >
    {item.values ? (
      item.values.map((value, i) => (
        <Box
          key={i}
          sx={{
            position: 'absolute',
            left: isHorizontal ? (isSplit ? '50%' : 0) : '50%',
            bottom: isHorizontal ? 0 : isSplit ? '50%' : 0,
            transform: getBarTransform(isHorizontal, isNegative),
            transformOrigin: isHorizontal ? 'center left' : 'bottom center',
            minHeight: 1,
            borderRadius: isHorizontal ? '0 12px 12px 0' : '12px 12px 0 0',
            bg: value.color,
            opacity: 1,
            maxWidth: isHorizontal ? 'none' : 150,
            transition: makeTransition('opacity'),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
          style={{
            width: '100%',
            height: `${sumBy(item.values?.slice(0, i + 1), 'value')}%`,
            zIndex: 99 - i,
            bottom: 0,
          }}
        >
          {!value.hideLabel && value.value ? (
            <Text
              as="div"
              variant="bodyLargeBold"
              sx={{
                flex: '1 1 auto',
                color: value.color === Colors.PURPLE ? 'white' : 'grey400',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pb: `${
                  (sumBy(item.values?.slice(0, i), 'value') / 100) * barHeight
                }px`,
              }}
            >
              {value.value}%
            </Text>
          ) : undefined}
        </Box>
      ))
    ) : item.value ? (
      <Box
        sx={{
          position: 'absolute',
          left: isHorizontal ? (isSplit ? '50%' : 0) : '50%',
          bottom: isHorizontal ? 0 : isSplit ? '50%' : 0,
          transform: getBarTransform(isHorizontal, isNegative),
          transformOrigin: isHorizontal ? 'center left' : 'bottom center',
          minHeight: 1,
          borderRadius: item.offset
            ? 12
            : isHorizontal
            ? '0 12px 12px 0'
            : '12px 12px 0 0',
          bg: item.color || (colorGetter ? colorGetter(item) : Colors.PURPLE),
          opacity: 0.8,
          maxWidth: 'none',
          transition: makeTransition('opacity'),
        }}
        style={{
          width: isHorizontal ? `${isSplit ? barSize / 2 : barSize}%` : '100%',
          height: isHorizontal ? '100%' : `${isSplit ? barSize / 2 : barSize}%`,
          bottom: item.offset ? `${item.offset}%` : '',
        }}
        className="Graph__bar"
      >
        {children}
      </Box>
    ) : (
      <Text
        as="div"
        variant="bodyBold"
        sx={{
          display: isHorizontal ? 'flex' : '',
          alignItems: 'center',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: isHorizontal ? '100%' : 'auto',
          mb: isHorizontal ? 0 : 3,
          textAlign: 'center',
          color: 'grey300',
          pl: isHorizontal ? 3 : 0,
        }}
      >
        N/A
      </Text>
    )}
  </Box>
)
