import { useEffect } from 'react'

import _ from 'lodash'
import { shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'

import { useArea } from 'hooks/useArea'
import { getAreas } from 'store/area/areaSlice'
import { AreaSource, AreaType } from 'store/area/types'
import { getAudit } from 'store/audit/auditSlice'
import { useCurrentlyViewingOrLatestSession } from 'store/company/useCurrentlyViewingOrLatestSession'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../store/hooks'

export const useAreas = ({
  group,
  local,
}: {
  group?: AreaSource
  local?: boolean
} = {}) => {
  const dispatch = useAppDispatch()
  const { company, loading, query, version } = useAppSelector(
    (state: State) => ({
      version: state.apiHeadersReducer.apiVersion,
      company: state.apiHeadersReducer.companyId,
      loading: state.areaReducer.loading,
      query: state.areaReducer.query,
    }),
    shallowEqual
  )
  const { code } = useParams<{ code: string }>()
  const { session: currentSession } = useCurrentlyViewingOrLatestSession()

  useArea(group)

  useEffect(() => {
    const currentQuery: any = {
      type: local || code ? AreaType.LOCAL : AreaType.SUB,
    }
    if (code) {
      currentQuery.parentAreaCode = code
    }
    if (group) {
      currentQuery.source = group
    }
    if (!loading && !_.isEqual(currentQuery, query) && company && version) {
      dispatch(getAreas(currentQuery))
    }
  }, [code, company, dispatch, group, loading, local, query, version])

  useEffect(() => {
    if (!loading && company && currentSession && version) {
      dispatch(getAudit())
    }
  }, [company, dispatch, currentSession, loading, version])
}
