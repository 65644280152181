import { shallowEqual } from 'react-redux'

import { useLocation } from 'react-router-dom'

import { useAuthRoles } from 'hooks/useAuthRoles'

import { State } from 'store/state'

import { useAppSelector } from '../store/hooks'

export const useRedirectOnboarding = () => {
  const { isAdmin } = useAuthRoles()

  const { hidden, user_verified } = useAppSelector(
    (state: State) => ({
      hidden: state.authReducer.user?.user_metadata?.onboarding?.hidden,
      invited: state.authReducer.user?.user_metadata?.invited,
      user_verified: state.authReducer.user?.email_verified,
    }),
    shallowEqual
  )
  const { pathname } = useLocation()

  if (!hidden && !user_verified) {
    if (pathname.includes('/verifyEmail')) {
      return ''
    }
    return `/verifyEmail`
  }

  return !hidden && !pathname.includes('/hello') && !isAdmin
    ? `/hello/1`
    : (pathname.includes('/hello') && hidden && '/') || ''
}
