import { FunctionComponent } from 'react'

import { Redirect } from 'react-router'

import { useAuthRoles } from '../../hooks/useAuthRoles'

export const Dashboard: FunctionComponent = () => {
  const { isAdmin } = useAuthRoles()

  if (isAdmin) {
    return <Redirect to="/admin" />
  }

  return <Redirect to="/reports" />
}
