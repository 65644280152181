import { QueryStatus } from 'store/types'

import { useScoreArea } from './useScoreArea'

export interface ReportsSidebarSubMenuItem {
  text: string
  url: string
  code: string
}

export const useReportsSidebar = () => {
  const { sub, isLoading, isError } = useScoreArea()

  if (sub.length === 0 || isLoading || isError) {
    return {
      sidebarItems: [],
      status: isError ? QueryStatus.ERROR : QueryStatus.LOADING,
    }
  }

  const sidebarItems = sub.map(({ code, title, url }: any) => ({
    code,
    text: title,
    url: url || `/reports/${code}`,
  }))

  return { sidebarItems, status: QueryStatus.SUCCESS }
}
