import { State } from 'store/state'

import { useAppSelector } from '../store/hooks'

export enum Roles {
  ADMIN = 'Admin',
  OWNER = 'Owner',
}

export const useAuthRoles = () => {
  const { roles } = useAppSelector((state: State) => ({
    roles: state.authReducer.user?.app_metadata?.roles ?? [],
  }))

  return {
    isAdmin: roles?.includes(Roles.ADMIN),
    isOwner: roles?.includes(Roles.OWNER),
  }
}
