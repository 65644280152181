import { useContext, useEffect, FunctionComponent } from 'react'

import { Heading, Text } from '@theme-ui/components'
import { useTranslation, Trans } from 'react-i18next'

import { shallowEqual } from 'react-redux'

import { config } from '@fairhq/common'

import { SignUpLayout } from 'layout/SignUpLayout'
import { checkRole } from 'store/account/accountSlice'
import { State } from 'store/state'

import AuthContext from 'utils/auth'

import { useAppDispatch, useAppSelector } from '../../../store/hooks'

export const VerifyEmail: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { loggedIn } = useContext(AuthContext)
  const { t } = useTranslation()
  const { user, userVerified, companyId } = useAppSelector(
    (state: State) => ({
      userVerified: state.authReducer.user?.email_verified,
      user: state.authReducer.user,
      companyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )
  const hasCompanyAndToken = companyId && localStorage.getItem(config.tokenKey)
  const shouldVerifyEmail =
    loggedIn && user && !userVerified && hasCompanyAndToken

  useEffect(() => {
    if (shouldVerifyEmail) {
      dispatch(checkRole())
    }
  }, [dispatch, shouldVerifyEmail])

  return (
    <SignUpLayout>
      <Heading variant="h1" pb={4} color="grey500">
        {t('auth.verifyEmail.title')}
      </Heading>
      <Text as="div" variant="bodyLarge">
        <Trans i18nKey="auth.verifyEmail.content" />
      </Text>
    </SignUpLayout>
  )
}
