import React, { FunctionComponent } from 'react'

import { Box, Link } from '@theme-ui/components'
import { maxBy, round } from 'lodash'

import { ThemeUIStyleObject } from 'theme-ui'

import { makeTransition } from '@fairhq/common'
import {
  ColorGetterFn,
  GraphBenchmark,
  GraphItem,
  GraphSortFn,
  GraphTooltipFormatter,
  GraphValueFormatter,
} from 'features/reports/graph/types'

import { GraphBar } from './GraphBar'
import { GraphContainer } from './GraphContainer'
import { GraphLabel } from './GraphLabel'
import { DEFAULT_TOOLTIP_FORMATTER, GraphTooltip } from './GraphTooltip'
import { GraphValue } from './GraphValue'

const PERCENT_FORMATTER: GraphValueFormatter = ({ max, value }) => {
  if (!value) {
    return null
  }
  const valueFormatted = max
    ? Math.round((value / max) * 100)
    : (value && Math.round(value)) || 0

  return valueFormatted && valueFormatted !== 0 ? `${valueFormatted}%` : ''
}

const DEFAULT_SORT_FUNCTION: GraphSortFn = (a, b) =>
  (a.value || -Infinity) - (b.value || -Infinity)

const DEFAULT_BAR_HEIGHT = 200

interface GraphProps {
  items: GraphItem[]
  showTooltip?: boolean
  tooltipFormatter?: GraphTooltipFormatter
  valueFormatter?: GraphValueFormatter
  autosort?: boolean
  sortFunction?: GraphSortFn
  max?: number
  maxMultiplier?: number
  colorGetter?: ColorGetterFn
  maxFromContext?: boolean
  barHeight?: number
  split?: boolean
  horizontal?: boolean
  sx?: ThemeUIStyleObject
  benchmark?: GraphBenchmark
}

export const Graph: FunctionComponent<GraphProps> = ({
  items,
  showTooltip = false,
  tooltipFormatter = DEFAULT_TOOLTIP_FORMATTER,
  valueFormatter = PERCENT_FORMATTER,
  sortFunction = DEFAULT_SORT_FUNCTION,
  autosort = false,
  max = 100,
  maxMultiplier = 1.1,
  colorGetter,
  maxFromContext,
  barHeight = DEFAULT_BAR_HEIGHT,
  split = false,
  benchmark,
  horizontal,
  sx,
}) => {
  let localItems = items?.slice() ?? []
  const isSplit = split || !!items?.find((item: any) => item.value < 0)
  const isHorizontal =
    horizontal !== undefined
      ? horizontal
      : localItems.length < 4 || localItems.length > 10

  const localMax = maxFromContext
    ? Math.abs(
        maxBy(items, (it: GraphItem) => (it.value ? Math.abs(it.value) : null))
          ?.value || 100
      ) * maxMultiplier
    : max

  if (autosort) {
    localItems = localItems.sort(sortFunction)
  }

  return (
    <GraphContainer
      isHorizontal={isHorizontal}
      barHeight={barHeight}
      benchmark={benchmark?.value}
      sx={{ ...sx }}
    >
      {benchmark && !isHorizontal && (
        <Box
          sx={{
            flex: isHorizontal ? '0 0 auto' : '0 1 150px',
            position: 'relative',
            display: 'flex',
            flexDirection: isHorizontal ? 'row' : 'column',
            px: isHorizontal ? 0 : 1,
            py: isHorizontal ? 1 : 0,
          }}
        >
          <GraphBar
            item={benchmark}
            barHeight={barHeight}
            barSize={benchmark.value * 100}
            isHorizontal={isHorizontal}
            isSplit={isSplit}
            isNegative={false}
            colorGetter={() => 'transparent'}
          >
            <GraphValue
              item={benchmark}
              valueFormatter={(item: any) =>
                item ? `${round(item.value || 0, 2) * 100}%` : ''
              }
              isValueLabelMoved={false}
              isNegative={false}
              isHorizontal={isHorizontal}
            />
          </GraphBar>
          <GraphLabel item={benchmark} isHorizontal={isHorizontal} />
        </Box>
      )}
      {localItems.map((item: any, i: number) => {
        const uniqueGraphKey = Math.random()
        const ItemComponent = item.url ? Link : Box
        const isNegative = item.value < 0
        const maxVal = item.max || localMax
        const ratio = Math.abs(item.value) / maxVal
        const barSize = ratio * 100 > 100 ? 100 : ratio * 100
        const isValueLabelMoved = ratio > (isSplit ? 0.75 : 0.85)

        return (
          <ItemComponent
            key={`${item.key}${i}${uniqueGraphKey}`}
            sx={{
              flex: '0 1 100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              pb: isHorizontal ? 1 : item.url ? 6 : 3,
              borderRadius: 12,
              boxShadow: 0,
              transition: `
                ${makeTransition('background')},
                ${makeTransition('box-shadow')}
              `,
            }}
          >
            <GraphBar
              key={`bar_${uniqueGraphKey}`}
              item={item}
              barHeight={barHeight}
              barSize={barSize}
              isHorizontal={isHorizontal}
              isSplit={isSplit}
              isNegative={isNegative}
              colorGetter={colorGetter}
            >
              <GraphValue
                item={item}
                valueFormatter={valueFormatter}
                isValueLabelMoved={isValueLabelMoved}
                isNegative={isNegative}
                isHorizontal={isHorizontal}
              />

              {showTooltip && (
                <GraphTooltip
                  item={item}
                  tooltipFormatter={tooltipFormatter}
                  isValueLabelMoved={isValueLabelMoved}
                  isNegative={isNegative}
                  isHorizontal={isHorizontal}
                />
              )}
            </GraphBar>

            <GraphLabel item={item} isHorizontal={isHorizontal} />
          </ItemComponent>
        )
      })}
    </GraphContainer>
  )
}
