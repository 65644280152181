import { FunctionComponent, useContext } from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'
import { RouteProps as DOMRouteProps } from 'react-router'
import { Redirect, Route } from 'react-router-dom'

import { Spinner } from '@fairhq/common'
import AuthContext from 'utils/auth'

import { useRedirectOnboarding } from './useRedirectOnboarding'

interface RouteProps extends DOMRouteProps {
  children: React.ReactNode
}

const PrivateRouteComponent: FunctionComponent<RouteProps> = ({
  children,
  ...restOfTheProps
}) => {
  const { loggedIn } = useContext(AuthContext)
  const redirect = useRedirectOnboarding()

  if (!loggedIn) {
    return <Spinner backdrop vertical />
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return <Route {...restOfTheProps}>{children}</Route>
}

export const PrivateRoute = withAuthenticationRequired(PrivateRouteComponent)
