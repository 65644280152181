import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'

import { markedNumberFormat } from 'utils/numbers'

import { GraphItem, GraphValueFormatter, GraphDeltaFormatter } from './types'

const DEFAULT_DELTA_FORMATTER: GraphDeltaFormatter = ({ delta }) =>
  markedNumberFormat(delta)

const getDelta: (item: GraphItem) => React.ReactNode = item => {
  const { delta } = item

  if (delta === undefined) {
    return null
  }

  return (
    <Text
      as="div"
      variant="labelBold"
      sx={{
        mt: '-6px',
        mb: '4px',
        color: delta === null ? 'grey300' : delta > 0 ? 'green' : 'red',
      }}
    >
      {DEFAULT_DELTA_FORMATTER(item)}%
    </Text>
  )
}

interface GraphValueProps {
  item: GraphItem
  valueFormatter: GraphValueFormatter
  isValueLabelMoved: boolean
  isNegative: boolean
  isHorizontal: boolean
}

export const GraphValue: FunctionComponent<GraphValueProps> = ({
  item,
  valueFormatter,
  isValueLabelMoved,
  isNegative,
  isHorizontal,
}) => {
  const verticalStyles = {
    bottom: '100%',
    mb: isValueLabelMoved ? -6 : '',
    left: 0,
    width: '100%',
  }

  const horizontalStyles = {
    display: 'flex',
    alignItems: 'center',
    left: isValueLabelMoved ? 'auto' : '100%',
    right: isValueLabelMoved ? 0 : 'auto',
    top: 0,
    height: '100%',
    px: 2,
  }

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        textAlign: 'center',
        transform: isNegative ? 'rotate(-180deg)' : '',

        ...(isHorizontal ? horizontalStyles : verticalStyles),
      }}
    >
      <Text
        as="div"
        variant="bodyLargeBold"
        sx={{ color: isValueLabelMoved ? 'white' : 'grey500' }}
      >
        {valueFormatter(item)}
      </Text>
      {getDelta(item)}
    </Flex>
  )
}
