import React, { FunctionComponent } from 'react'

interface AssessmentProps {
  className?: string
  fill?: string
}

export const Assessment: FunctionComponent<AssessmentProps> = ({
  className,
  fill = 'currentColor',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6H2V8H4V6ZM6 8V6H22V8H6ZM6 11V13H22V11H6ZM6 16V18H22V16H6ZM2 11H4V13H2V11ZM4 16H2V18H4V16Z"
    />
  </svg>
)
