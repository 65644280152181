import { combineReducers } from '@reduxjs/toolkit'

import { emptySplitApi } from 'emptySplitApi'

import { insightsReducer } from '../features/reports/store/insightSlice'

import { accountReducer } from './account/accountSlice'
import { apiHeadersReducer } from './apiHeaders/apiHeadersSlice'
import { areaReducer } from './area/areaSlice'
import { assessmentReducer } from './assessment/assessmentSlice'
import { auditReducer } from './audit/auditSlice'
import { authReducer } from './auth/authSlice'
import { companyReducer } from './company/companySlice'
import { customerReducer } from './customer/customerSlice'
import { paymentReducer } from './payment/paymentSlice'
import { questionSessionReducer } from './questionSession/questionSessionSlice'
import { statusReducer } from './status/statusSlice'

export const reducer = combineReducers({
  accountReducer,
  areaReducer,
  apiHeadersReducer,
  assessmentReducer,
  auditReducer,
  authReducer,
  companyReducer,
  customerReducer,
  paymentReducer,
  questionSessionReducer,
  statusReducer,
  insightsReducer,
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
})
