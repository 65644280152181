import { FunctionComponent } from 'react'

import { Flex, Text, FlexProps } from '@theme-ui/components'
import styled from 'styled-components'

import { baseColors, Round } from '@fairhq/common'

import { Benchmark } from 'store/score/types'

import { useBenchmarks } from './hooks/useBenchmarks'

const BenchmarkStyledCard = styled.div`
  display: flex;
  color: ${baseColors.grey500};
  background-color: ${baseColors.purple200};
  border-radius: 16px;
  align-items: center;
  padding: 8px 16px;
`

const StyledCard = styled.div`
  display: flex;
  width: 100%;
  color: ${baseColors.grey500};
  background-color: white;
  border-radius: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  grid-column: span 1;
  gap: 24px;
`

export type SubAreaCardProps = {
  title?: string
  status: string
  areaAirtableId: string
} & FlexProps

export const SubAreaCard: FunctionComponent<SubAreaCardProps> = ({
  title,
  status,
  areaAirtableId,
}) => {
  const { localAreasBenchmark } = useBenchmarks()

  const getBenchmark = ({
    localAreasBenchmark,
  }: {
    localAreasBenchmark: Benchmark[]
  }) => {
    const benchmark = localAreasBenchmark.find(benchmark =>
      benchmark.areas_airtable_ids.includes(areaAirtableId)
    )

    return benchmark ? benchmark.value : ''
  }

  const statusColors = (status: string) => ({
    infoBg:
      status === 'inProgress'
        ? baseColors.purple200
        : (status === 'completed' && '#DDF4DE') || baseColors.coolGrey300,
    infoStroke:
      status === 'inProgress'
        ? baseColors.purple400
        : (status === 'completed' && '#9CDEA1') || undefined,
    infoFill:
      status === 'inProgress'
        ? baseColors.purple700
        : (status === 'completed' && '#2F8E37') || undefined,
    infoColor:
      status === 'inProgress'
        ? baseColors.purple800
        : (status === 'completed' && '#196620') || baseColors.purple900,
    infoLabel:
      status === 'inProgress'
        ? 'in progress'
        : (status === 'completed' && 'completed') || 'not started',
  })

  return (
    <StyledCard>
      <Flex sx={{ gap: '24px' }}>
        <Text as="div" sx={{ fontWeight: 'bold' }}>
          {title}
        </Text>
      </Flex>

      <Flex sx={{ gap: '24px', flexShrink: 0 }}>
        <Flex
          as="div"
          sx={{
            alignItems: 'center',
            display: 'inline-flex',
            backgroundColor: statusColors(status).infoBg,
            color: statusColors(status).infoColor,
            padding: '4px 16px',
            borderRadius: '60px',
          }}
        >
          <Round
            width="14px"
            height="14px"
            outline={statusColors(status).infoFill}
            stroke={statusColors(status).infoStroke}
          />
          <Text
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '20px',
              marginLeft: '5px',
            }}
          >
            {statusColors(status).infoLabel}
          </Text>
        </Flex>
        {localAreasBenchmark && (
          <BenchmarkStyledCard>
            <Text as="div" sx={{ fontWeight: 'normal', fontSize: '12px' }}>
              {`${getBenchmark({ localAreasBenchmark })}%`}
            </Text>
          </BenchmarkStyledCard>
        )}
      </Flex>
    </StyledCard>
  )
}
