import { useCallback, useEffect, useState } from 'react'

import { shallowEqual } from 'react-redux'
import { useHistory } from 'react-router'
import { useInterval } from 'react-use'

import { config } from '@fairhq/common'
import { useAuditBy } from 'hooks/useAuditBy'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useGenerateScoresMutation } from 'store/score/scoreApi'
import { State } from 'store/state'

import { useAppSelector } from '../../../store/hooks'

export const useRequestInsights = () => {
  const { companyId, apiVersion, sessionId } = useAppSelector(
    (state: State) => ({
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
      companyId: state.apiHeadersReducer.companyId,
    }),
    shallowEqual
  )
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })
  const [processing, setProcessing] = useState(false)
  const { completed } = useAuditBy()
  const history = useHistory()

  const [generateScores, { isSuccess, isLoading }] = useGenerateScoresMutation()

  const refreshCompany = useCallback(() => {
    if (localStorage.getItem(config.audit) === 'generate' && isScorable) {
      setProcessing(true)
    } else {
      localStorage.removeItem(config.audit)
      setProcessing(false)
    }
  }, [isScorable])

  useEffect(() => {
    if (completed) {
      refreshCompany()
    }
  }, [completed, refreshCompany])

  useEffect(() => {
    if (isSuccess) {
      history.push('/reports')
    }
  }, [isSuccess, history])

  useInterval(() => {
    if (processing && completed) {
      refreshCompany()
    }
  }, 30000)

  const requestInsights = () => {
    setProcessing(true)
    localStorage.setItem(config.audit, 'generate')
    if (companyId) {
      generateScores({ apiVersion, sessionId })
    }
  }

  return { requestInsights, processing, isRequestInsightsLoading: isLoading }
}
