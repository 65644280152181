import { FunctionComponent, useEffect, useState } from 'react'

import { Grid, Heading } from '@theme-ui/components'
import { shallowEqual } from 'react-redux'

import { Link } from 'react-router-dom'

import { Flex } from 'theme-ui'

import { baseColors, Button, Text } from '@fairhq/common'
import { ConfirmNewSessionModal } from 'features/reports/ConfirmNewSessionModal'
import { useRequestInsights } from 'features/reports/hooks/useRequestInsights'
import { useAreas } from 'hooks/useAreas'
import { useAuditBy } from 'hooks/useAuditBy'
import { useAuthRoles } from 'hooks/useAuthRoles'
import { getAreas } from 'store/area/areaSlice'
import { AreaType } from 'store/area/types'
import { useGetIsScorableQuery } from 'store/audit/auditApiWithQuery'
import { useCreateSessionMutation } from 'store/company/companyApiWithQuery'
import { State } from 'store/state'

import { Layout } from '../../layout/Layout'
import { PageTop } from '../../layout/PageTop'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { AssessmentSidebar } from './AssessmentSidebar'
import AreaCard from './components/AreaCard'

const Assessments: FunctionComponent = () => {
  const { isAdmin } = useAuthRoles()

  const dispatch = useAppDispatch()
  const { loading, areas, viewingCompanyId, apiVersion, sessionId } =
    useAppSelector(
      (state: State) => ({
        viewingCompanyId: state.apiHeadersReducer.companyId,
        areas: state.areaReducer.areas || [],
        loading: state.areaReducer.loading,
        apiVersion: state.apiHeadersReducer.apiVersion,
        sessionId: state.apiHeadersReducer.sessionId,
      }),
      shallowEqual
    )
  const [reAuditVisible, setReAuditVisible] = useState(false)

  useAreas({})
  const [
    createSession,
    { isLoading: isCreateSessionLoading, isSuccess: isCreateSessionSuccess },
  ] = useCreateSessionMutation()
  const { requestInsights, isRequestInsightsLoading } = useRequestInsights()
  const { data: isScorable } = useGetIsScorableQuery({ apiVersion, sessionId })

  useEffect(() => {
    if (!loading && areas?.length === 0) {
      dispatch(getAreas({ type: AreaType.SUB }))
    }
  })

  const { isCompanyAssessmentCompleted } = useAuditBy(sessionId, apiVersion)

  useEffect(() => {
    if (isCreateSessionSuccess) {
      setReAuditVisible(false)
    }
  }, [isCreateSessionSuccess])

  const onCloseModal = () => {
    setReAuditVisible(false)
  }
  const onSubmitModal = () => {
    createSession()
  }

  if (!isAdmin) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  if (!viewingCompanyId) {
    return (
      <Layout>
        <p>Pelase select a company first</p>
        <Link to="/admin">Take me there</Link>
      </Layout>
    )
  }

  return (
    <Layout dark sidebar={<AssessmentSidebar />}>
      <PageTop>
        <Heading>Audit</Heading>
        <Button onClick={() => setReAuditVisible(true)}>Start new audit</Button>
      </PageTop>

      {!isScorable && isCompanyAssessmentCompleted && (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '24px',
            borderRadius: '20px',
            backgroundColor: baseColors.purple200,
            marginBottom: '36px',
          }}
        >
          <Text element="p" type="body" fontWeight={700} size="md">
            Looks like this Audit is at 100%! Are you ready to unlock reports?
          </Text>
          <Button
            loading={isRequestInsightsLoading}
            onClick={() => requestInsights()}
          >
            Unlock reports
          </Button>
        </Flex>
      )}

      {areas.length > 0 && (
        <Grid columns={2} gap={4} sx={{ rowGap: 2 }}>
          {areas
            ?.filter(({ sources }) => sources?.includes('company'))
            .map(({ code, title: areaTitle, image }) => (
              <AreaCard
                key={code}
                label={areaTitle}
                code={code}
                image={image}
              />
            ))}
        </Grid>
      )}
      {reAuditVisible && (
        <ConfirmNewSessionModal
          onClose={onCloseModal}
          onSubmit={onSubmitModal}
          title="Copy latest audit to a new session"
          body="Create a new session to keep a record of previous audits and reports.
          Answers from the most recent audit will be copied to the new session
          so you only need to update what’s changed."
          isLoading={isCreateSessionLoading}
        />
      )}
    </Layout>
  )
}

export default Assessments
