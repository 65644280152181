import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { waitForResponse } from '@fairhq/common'

import { handleErrorState } from 'store/helpers/handleErrorState'
import { isClearAccount } from 'store/helpers/isClearAccount'
import { isClearAll } from 'store/helpers/isClearAll'
import { isFulfilled } from 'store/helpers/isFulfilled'
import { isPending } from 'store/helpers/isPending'
import { isRejected } from 'store/helpers/isRejected'

import { questionSessionActions } from '../questionSession/questionSessionSlice'

import { areaApi } from './areaApi'
import { AreaState, AreasQueryParams } from './types'

const clear = createAction('area/clear')

export const getArea = createAsyncThunk(
  'area/getArea',
  async (query: AreasQueryParams, { getState }) =>
    waitForResponse({
      callback: () => areaApi.getAreas(getState, query),
    })
)
export const getAreas = createAsyncThunk(
  'area/getAreas',
  async (query: AreasQueryParams, { getState }) =>
    waitForResponse({
      callback: () => areaApi.getAreas(getState, query),
    })
)
export const getAssessmentsByArea = createAsyncThunk(
  'area/getAssessmentsByArea',
  async (areaId: number, { dispatch, getState }) => {
    const results = await waitForResponse({
      callback: () => areaApi.getAssessmentsByArea(getState, areaId),
    })
    dispatch(questionSessionActions.saveAssessments(results))
    return results
  }
)
export const getQuestionsByArea = createAsyncThunk(
  'area/getQuestionsByArea',
  async (code: string, { dispatch, getState }) => {
    const results = await waitForResponse({
      callback: () => areaApi.getQuestionsByArea(getState, code),
    })
    dispatch(questionSessionActions.setQuestionsAfterGet(results))
    return results
  }
)

const initialState: Partial<AreaState> = {
  loading: false,
  questionsLoading: false,
  assessmentsLoading: false,
  documentTypesLoading: false,
}

const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(clear, () => initialState)
      .addCase(getArea.pending, state => {
        state.error = undefined
        state.loading = true
      })
      .addCase(getArea.fulfilled, (state, action) => {
        state.loading = false
        const [area] = action.payload
        state.area = area
        if (area?.id) {
          state.assessments = {
            ...state.assessments,
            [area.id]: { loaded: false, assessments: [] },
          }
        }
      })
      .addCase(getAreas.pending, (state, action) => {
        state.error = undefined
        state.loading = true
        state.query = action.meta.arg
      })
      .addCase(getAreas.fulfilled, (state, action) => {
        state.loading = false
        state.areas = action.payload
      })
      .addCase(getAssessmentsByArea.fulfilled, (state, action) => {
        state.assessments = {
          ...state.assessments,
          [action.meta.arg]: { assessments: action.payload, loaded: true },
        }
      })
      .addCase(getQuestionsByArea.pending, state => {
        state.error = undefined
        state.questionsLoading = true
      })
      .addCase(getQuestionsByArea.fulfilled, (state, action) => {
        state.questionsLoading = false
        state.questions = {
          ...state.questions,
          [action.meta.arg]: action.payload,
        }
      })

      .addMatcher(isRejected('area'), handleErrorState)
      .addMatcher(isPending('area'), state => {
        state.error = undefined
        state.loading = true
      })
      .addMatcher(isFulfilled('area/'), state => {
        state.error = undefined
        state.loading = false
      })
      .addMatcher(isClearAccount(), () => initialState)
      .addMatcher(isClearAll(), () => initialState)
  },
})

export const { actions: areaActions, reducer: areaReducer } = areaSlice
