import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { config } from '@fairhq/common'
import { State } from 'store/state'

export enum TagTypes {
  CAMPAIGNS = 'Campaigns',
  ACCOUNT = 'Account',
  ACCOUNTS = 'Accounts',
  SCORABLE = 'Scorable',
  SESSIONS = 'Sessions',
  SCORES = 'Scores',
  EVENTS = 'Events',
  RECOMMENDATIONS = 'Recommendations',
  EMPLOYEES = 'Employees',
}

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      // Note: these default headers are applied _after_ the headers
      // defined on a specific endpoint, so they will override anything
      // that's been defined on an endpoint
      const token = localStorage.getItem(config.tokenKey)
      const state = getState() as State

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      const accountHeader = headers.get('fairhq-account')
      if (!accountHeader) {
        const accountId = state?.apiHeadersReducer?.accountId?.toString()
        headers.set('fairhq-account', accountId || '')
      }

      const companyId = state?.apiHeadersReducer?.companyId?.toString()
      headers.set('fairhq-company', companyId || '')

      return headers
    },
  }),
  tagTypes: [
    TagTypes.CAMPAIGNS,
    TagTypes.ACCOUNT,
    TagTypes.ACCOUNTS,
    TagTypes.SCORABLE,
    TagTypes.SESSIONS,
    TagTypes.SCORES,
    TagTypes.EVENTS,
    TagTypes.RECOMMENDATIONS,
    TagTypes.EMPLOYEES,
  ],
  endpoints: () => ({}),
})
