import distrategy from '../../../assets/areas/d_i_strategy.svg'
import employeeComplaints from '../../../assets/areas/employee_complaints.svg'
import employeeExit from '../../../assets/areas/employee_exit.svg'
import hiring from '../../../assets/areas/hiring.svg'
import learningDevelopment from '../../../assets/areas/learning_and_development.svg'
import onboarding from '../../../assets/areas/onboarding.svg'
import otherPolicies from '../../../assets/areas/other_policies.svg'
import payBonus from '../../../assets/areas/pay_and_bonus.svg'
import performance from '../../../assets/areas/performance.svg'
import promotion from '../../../assets/areas/promotion.svg'

const icons: Record<string, string> = {
  'equality.dnIstrategy': distrategy,
  'equality.hiring': hiring,
  'equality.onboarding': onboarding,
  'equality.performance': performance,
  'equality.payAndBonus': payBonus,
  'equality.promotion': promotion,
  'equality.learning': learningDevelopment,
  'equality.complaints': employeeComplaints,
  'equality.employeeAttrition': employeeExit,
  'equality.keyPolicies': otherPolicies,
}

export default icons
