import React from 'react'

import { Grid } from '@theme-ui/components'
import { orderBy } from 'lodash'
import { shallowEqual } from 'react-redux'

import { State } from 'store/state'

import { useAppSelector } from '../../../store/hooks'

import SurveyCard from './SurveyCard'

const Surveys = () => {
  const { areas } = useAppSelector(
    (state: State) => ({
      areas: state.areaReducer.areas?.filter(({ sources }) =>
        sources?.includes('company')
      ),
    }),
    shallowEqual
  )

  if (!areas || areas?.length === 0) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <Grid gap={1}>
      {orderBy(areas, 'title')
        ?.filter(({ sources }) => sources?.includes('company'))
        ?.map(({ code, title }) => (
          <SurveyCard key={code + Math.random()} label={title} code={code} />
        ))}
    </Grid>
  )
}

export default Surveys
