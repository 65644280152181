import React, { FunctionComponent } from 'react'

import { Flex, Text } from '@theme-ui/components'
import { Link } from 'react-router-dom'

import { makeTransition } from '@fairhq/common'

import Icon from 'ui-kit/Icon'

import { DisabledMenuIcon } from './DisabledMenuIcon'

type Props = {
  text: string
  url: string
  opened?: boolean
  onToggle?: () => void
  active?: boolean
  enabled?: boolean
  hasChildren?: boolean
}

export const MenuItem: FunctionComponent<Props> = ({
  text,
  url,
  active,
  opened = false,
  onToggle,
  hasChildren = false,
  enabled = true,
}) => (
  <Link to={enabled ? url : '#'}>
    <Flex
      sx={{
        pointerEvents: enabled ? undefined : 'none',
        position: 'relative',
        pl: 7,
        height: 54,
        alignItems: 'center',
        color: active ? 'purple' : 'grey500',
        transition: makeTransition('color'),
        bg: active ? 'grey100' : 'white',
        '&:hover': { color: 'purple' },
        '&:before': {
          content: "''",
          position: 'absolute',
          top: 0,
          left: 0,
          width: 3,
          height: '100%',
          bg: 'purple',
          display: active ? 'block' : 'none',
        },
      }}
    >
      <Flex>
        {enabled ? undefined : <DisabledMenuIcon />}
        <Text as="div" sx={{ fontWeight: 700 }}>
          {text}
        </Text>
      </Flex>
      {hasChildren && enabled && (
        <Flex
          sx={{
            ml: 'auto',
            width: 54,
            height: 54,
            alignItems: 'center',
            justifyContent: 'center',
            userSelect: 'none',
          }}
          onClick={e => {
            e.preventDefault()
            onToggle && onToggle()
          }}
        >
          <Icon name={opened ? 'arrowUp' : 'arrowDown'} small />
        </Flex>
      )}
    </Flex>
  </Link>
)
