import React, { FunctionComponent, useEffect } from 'react'

import { Route, Switch, Redirect } from 'react-router-dom'
import { useLocation } from 'react-use'

import { config } from '@fairhq/common'
import { Admin } from 'features/admin/Admin'
import { Dashboard } from 'features/dashboard/Dashboard'
import { VerifyEmail } from 'features/onboarding/components/VerifyEmail'
import { Welcome } from 'features/onboarding/components/Welcome'
import { ReportsLandingPage } from 'features/reports/ReportsLandingPage'
import { SubAreaPage } from 'features/reports/SubAreaPage'
import { BillingSettings } from 'features/settings/BillingSettings'
import { EditBillingAddress } from 'features/settings/EditBillingAddress'
import { EditPersonalDetails } from 'features/settings/EditPersonalDetails'
import { useAlert } from 'hooks/useAlert'
import { AssessmentQuestions } from 'modules/assessmentQuestions/AssessmentQuestions'
import AreaDetail from 'modules/assessments/AreaDetail'
import { NotFound } from 'modules/notFound/NotFound'

import { AccountSettings } from '../features/settings/AccountSettings'

import { Settings } from '../features/settings/Settings'
import Assessments from '../modules/assessments/Assessments'

import { Login } from './Login'
import { PrivateRoute } from './PrivateRoute'

const RoutesComponent: FunctionComponent = () => {
  useAlert()
  const location = useLocation()

  useEffect(() => {
    window?.gtag?.('config', config.analytics, { page_path: location.pathname })
  }, [location.pathname])

  return (
    <Switch>
      <Route
        exact
        path="/home"
        render={() => (
          <PrivateRoute key="/home">
            <Dashboard />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/admin"
        render={() => (
          <PrivateRoute key="/admin">
            <Admin />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/reports"
        render={() => (
          <PrivateRoute key="/reports" exact path="/reports">
            <ReportsLandingPage />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/reports/:code"
        render={() => (
          <PrivateRoute key="/reports/:code">
            <SubAreaPage />
          </PrivateRoute>
        )}
      />

      <Route
        exact
        path="/hello/:step"
        render={() => (
          <PrivateRoute>
            <Welcome />
          </PrivateRoute>
        )}
      />

      <Route
        exact
        path="/verifyEmail"
        render={() => (
          <PrivateRoute>
            <VerifyEmail />
          </PrivateRoute>
        )}
      />

      <Route
        exact
        path="/assessment/:code/questions"
        render={() => (
          <PrivateRoute>
            <AssessmentQuestions />
          </PrivateRoute>
        )}
      />

      <Route
        exact
        path="/settings/account"
        render={() => (
          <PrivateRoute key="/settings/account">
            <AccountSettings />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/settings/account/personal"
        render={() => (
          <PrivateRoute key="/settings/account/personal">
            <EditPersonalDetails />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/settings/billing"
        render={() => (
          <PrivateRoute key="/settings/billing">
            <BillingSettings />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/settings/billing/address"
        render={() => (
          <PrivateRoute key="/settings/billing/address">
            <EditBillingAddress />
          </PrivateRoute>
        )}
      />
      <Route
        path="/settings"
        render={() => (
          <PrivateRoute key="/settings">
            <Settings />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/audit"
        render={() => (
          <PrivateRoute key="/audit">
            <Assessments />
          </PrivateRoute>
        )}
      />
      <Route
        exact
        path="/audit/:code"
        render={() => (
          <PrivateRoute key="/audit/:code">
            <AreaDetail />
          </PrivateRoute>
        )}
      />

      <Route
        exact
        path="/assessments"
        render={() => (
          <PrivateRoute>
            <Redirect from="/assessments" to="/audit" />
          </PrivateRoute>
        )}
      />
      <Route
        path="/assessments/:code"
        render={() => (
          <PrivateRoute>
            <Redirect from="/assessments/:code" to="/audit/:code" />
          </PrivateRoute>
        )}
      />

      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}

export const Routes = React.memo(RoutesComponent)
