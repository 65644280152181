import { emptySplitApi } from '../../../emptySplitApi'
import { ApiVersion, SessionId } from '../../../store/apiHeaders/types'

const badgesApi = emptySplitApi.injectEndpoints({
  endpoints: build => ({
    getBadge: build.query<
      { level: number },
      { apiVersion: ApiVersion; sessionId: SessionId }
    >({
      query: ({ apiVersion, sessionId }) => ({
        url: `/companies/badge`,
        headers: {
          'fairhq-version': apiVersion || '',
          'fairhq-session': sessionId ? sessionId.toString() : '',
        },
      }),
    }),
  }),
})

export const { useGetBadgeQuery } = badgesApi
