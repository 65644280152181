import { FunctionComponent } from 'react'

import { Box, Flex, Text } from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { baseColors, makeTransition } from '@fairhq/common'
import Sidebar from 'layout/Sidebar'

export const EmptySidebar: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Sidebar sx={{ py: 6 }}>
      <Box sx={{ position: 'sticky', bottom: 0 }}>
        <Link to="/reports">
          <Flex
            sx={{
              mb: 5,
              px: 7,
              height: 54,
              alignItems: 'center',
              transition: makeTransition('color'),
              '&:hover': { color: baseColors.purple },
              background: baseColors.grey100,
              borderLeft: '2px solid blue',
            }}
          >
            <Text as="div" variant="caps">
              {t('nav.reports')}
            </Text>
          </Flex>
        </Link>
      </Box>
    </Sidebar>
  )
}
