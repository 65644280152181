import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { Flex, SxProp } from 'theme-ui'

import { Text } from '@fairhq/common'
import { DATE_FORMAT_SESSION_URL } from 'const'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import { getAudit } from 'store/audit/auditSlice'
import { useGetSessionsQuery } from 'store/company/companyApiWithQuery'
import { companyActions } from 'store/company/companySlice'
import { useCurrentlyViewingOrLatestSession } from 'store/company/useCurrentlyViewingOrLatestSession'
import { useAppDispatch } from 'store/hooks'
import { Select } from 'ui-kit/Select'
import { toDate } from 'utils/dates'

import { clear as clearInsights } from '../reports/store/insightSlice'

interface SessionDropdownProps {
  sx?: SxProp
}

export const SessionDropdown: FunctionComponent<SessionDropdownProps> = ({
  sx = {},
}) => {
  const dispatch = useAppDispatch()

  const { i18n } = useTranslation()
  const { session: latestSession } = useCurrentlyViewingOrLatestSession()
  const { data: sessions } = useGetSessionsQuery()

  if ((sessions || []).length <= 1) {
    return <></> // eslint-disable-line react/jsx-no-useless-fragment
  }

  const sessionsWithLabels = sessions?.map((session, index) => ({
    id: session.id,
    startDate: session.start_date,
    sessionLabel: sessions.length - index,
  }))

  return (
    <Flex sx={{ alignItems: ' center', gap: '8px' }}>
      <Text element="label" type="body" size="xs" fontWeight={500}>
        Now viewing:
      </Text>
      <Select
        onChange={e => {
          const newSession = sessions?.find(
            ({ start_date }) =>
              toDate(i18n.language, start_date).format(
                DATE_FORMAT_SESSION_URL
              ) === e?.target?.value
          )

          if (newSession) {
            dispatch(apiHeadersActions.setSessionId(newSession.id))
            dispatch(apiHeadersActions.setApiVersion(newSession.api_version))
            dispatch(clearInsights())
            dispatch(companyActions.setSessionCurrentlyViewing(newSession.id))
            dispatch(getAudit())
          }
        }}
        sx={{ width: 180, height: 48, ...sx }}
        value={
          latestSession?.start_date &&
          toDate(i18n.language, latestSession?.start_date).format(
            DATE_FORMAT_SESSION_URL
          )
        }
      >
        {sessionsWithLabels?.map(({ startDate: date, sessionLabel }) => (
          <option
            key={date}
            value={toDate(i18n.language, date).format(DATE_FORMAT_SESSION_URL)}
          >
            {`Session ${sessionLabel}`}
          </option>
        ))}
      </Select>
    </Flex>
  )
}
