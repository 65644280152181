import { User } from '@auth0/auth0-react'

import { IntercomProps } from 'react-use-intercom'

import { getIntercomCompanyId } from 'utils/getIntercomCompanyId'

export const identifyIntercomUser = (
  user: User | undefined,
  userHash: string,
  companyId: number | undefined,
  companyName: string | undefined,
  updateIntercom: (user: Partial<IntercomProps> | undefined) => void,
  shutdownIntercom: () => void,
  isAdmin: boolean
) => {
  if (isAdmin) {
    shutdownIntercom()
  } else {
    const intercomUser: Partial<IntercomProps> = {
      email: user?.email || '',
      userHash,
    }

    if (companyId && companyName) {
      intercomUser.company = {
        companyId: getIntercomCompanyId(companyId),
        name: companyName,
      }
    }

    updateIntercom(intercomUser)
  }
}
