import { FunctionComponent, useEffect, useState } from 'react'

import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Link as ThemeLink,
} from '@theme-ui/components'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Link } from 'react-router-dom'

import { useIntercom } from 'react-use-intercom'

import { DATE_FORMAT_LIST } from 'const'
import { Layout } from 'layout/Layout'
import { getSubscription, getCustomer } from 'store/customer/customerSlice'
import { Stripe } from 'store/customer/types'
import { getInvoices } from 'store/payment/paymentSlice'
import { State } from 'store/state'
import Field from 'ui-kit/Field'
import { Separator } from 'ui-kit/Separator'
import { Table, TableCell, TableRow } from 'ui-kit/Table'
import { convertToIntercomSubscription } from 'utils/convertToIntercomSubscription'
import { toCurrency } from 'utils/currency'
import { toDate } from 'utils/dates'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { SettingsSidebar } from './SettingsSidebar'

export const BillingSettings: FunctionComponent = () => {
  const { t, i18n } = useTranslation()
  const { update: updateIntercom } = useIntercom()
  const dispatch = useAppDispatch()
  const [isBootstrapped, setIsBootstrapped] = useState(false)
  const { subscription, customer, invoices, loading, plan } = useAppSelector(
    (state: State) => ({
      subscription: state.customerReducer.subscription,
      plan: state.customerReducer.subscription?.subscriptionItems?.[0]?.plan,
      customer: state.customerReducer.customer,
      invoices: state.paymentReducer.invoices,
      loading: state.customerReducer.loading || state.paymentReducer.loading,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (!loading) {
      if (!subscription && !isBootstrapped) {
        setIsBootstrapped(true)
        dispatch(getSubscription())
          // Redux Toolkit isn't great at typing what is returned from createAsyncThunk
          // and thinks we're returning nothing. Instructions on how to change that really
          // aren't clear
          // @ts-ignore
          .unwrap()
          .then((response: Stripe) => {
            updateIntercom(convertToIntercomSubscription(response))
          })
      }
      if (!customer && subscription) {
        dispatch(getCustomer())
      }
      if (!invoices && subscription) {
        dispatch(getInvoices())
      }
    }
  }, [
    dispatch,
    loading,
    customer,
    invoices,
    subscription,
    isBootstrapped,
    updateIntercom,
  ])

  return (
    <Layout sidebar={<SettingsSidebar />} maxContentWidth={660} dark>
      <Heading variant="subtitle" sx={{ mb: 10 }}>
        {t('settings.billing')}
      </Heading>

      <Separator />

      {loading && !subscription ? (
        <Text as="div">{t('loading')}.</Text>
      ) : (
        <>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Box>
              <Text as="div" variant="h3">
                {plan?.nickname}
              </Text>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Flex sx={{ alignItems: 'center' }}>
                <Text as="div" variant="subtitle">
                  {toCurrency({
                    number: (subscription?.latest_invoice?.total || 0) / 100,
                    language: i18n.language,
                  })}{' '}
                  / year
                </Text>
              </Flex>
            </Box>
          </Flex>

          {/*
          <Separator />

          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 5,
            }}
          >
            <Heading as="h3" variant="h3">
              {t('settings.billing.paymentMethod')}
            </Heading>
            {!!card && (
              <Link to="/settings/billing/method">
                <Text as="div" variant="capsLink">
                  {t('settings.billing.changeCard')}
                </Text>
              </Link>
            )}
          </Flex>

          {card ? (
            <Grid gap={3}>
              <Field
                label={t('hello.payment.cardNumber')}
                value={`**** **** **** ${card?.last4}`}
                inline
                textValue
              />
              <Field
                label={t('settings.billing.expirationDate')}
                value={`${card?.exp_month}/${card?.exp_year}`}
                inline
                textValue
              />
            </Grid>
          ) : (
            'Loading...'
          )} */}

          <Separator />

          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 5,
            }}
          >
            <Heading as="h3" variant="h3">
              {t('settings.billing.billingInfo')}
            </Heading>
            <Link to="/settings/billing/address">
              <Text as="div" variant="capsLink">
                {t('settings.billing.changeBillingAddress')}
              </Text>
            </Link>
          </Flex>
          {customer ? (
            <Grid gap={3}>
              <Field
                label={t('form.name.label')}
                value={customer?.name}
                inline
                textValue
              />
              <Field
                label={t('form.email.label')}
                value={customer?.email}
                inline
                textValue
              />
              <Field
                label={t('settings.billing.address')}
                value={[
                  customer?.address_line1,
                  customer?.address_line2,
                  customer?.address_city,
                ]
                  .filter(Boolean)
                  .join(', ')}
                inline
                textValue
              />
              <Field
                label={t('form.postal_code.label')}
                value={[
                  customer?.address_postal_code,
                  customer?.address_country,
                ]
                  .filter(Boolean)
                  .join(', ')}
                inline
                textValue
              />
            </Grid>
          ) : (
            t('loading')
          )}

          <Separator />

          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 5,
            }}
          >
            <Heading as="h3" variant="h3">
              {t('settings.billing.invoices')}
            </Heading>
          </Flex>

          <Table
            headers={[
              t('settings.billing.invoice.name'),
              t('settings.billing.invoice.amount'),
              t('settings.billing.invoice.download'),
            ]}
            items={invoices || []}
            renderItem={item => (
              <TableRow key={item.id}>
                <TableCell>
                  {toDate(i18n.language, item.created).format(DATE_FORMAT_LIST)}
                </TableCell>
                <TableCell>
                  {toCurrency({
                    number: item.amount_paid / 100,
                    language: i18n.language,
                  })}
                </TableCell>
                <TableCell>
                  <ThemeLink
                    variant="capsLink"
                    href={item.invoice_pdf}
                    target="_blank"
                    rel="noopener norefer"
                  >
                    <Text as="div" variant="capsLink">
                      {t('settings.billing.invoice.pdf')}
                    </Text>
                  </ThemeLink>
                </TableCell>
              </TableRow>
            )}
          />
        </>
      )}
    </Layout>
  )
}
