import { useEffect } from 'react'

import { shallowEqual } from 'react-redux'

import { useIntercom } from 'react-use-intercom'

import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import { getIntercomHash } from 'store/auth/authSlice'
import { useGetApiVersionQuery } from 'store/company/companyApiWithQuery'
import { getAPIVersion } from 'store/company/companySlice'
import { State } from 'store/state'

import { getIntercomCompanyId } from 'utils/getIntercomCompanyId'

import { useAppDispatch, useAppSelector } from '../store/hooks'

import { identifyIntercomUser } from './indentifyIntercomUser'
import { useAuthRoles } from './useAuthRoles'

export const useCompany = () => {
  const dispatch = useAppDispatch()
  const { isAdmin } = useAuthRoles()

  const {
    user,
    jwt,
    userEmailVerified,
    company,
    companyId,
    companyName,
    apiVersion,
  } = useAppSelector(
    (state: State) => ({
      company: state.companyReducer.company,
      user: state.authReducer.user,
      jwt: state.authReducer.jwt,
      userEmailVerified: state.authReducer.user?.email_verified,
      companyId: state.companyReducer.company?.id,
      companyName: state.companyReducer.company?.name,
      apiVersion: state.apiHeadersReducer.apiVersion,
    }),
    shallowEqual
  )

  const {
    data: account,
    isLoading: isAccountLoading,
    refetch: refetchAccount,
  } = useGetAccountQuery({ apiVersion })

  useGetApiVersionQuery(undefined, {
    skip: !jwt,
  })

  const { update: updateIntercom, shutdown: shutdownIntercom } = useIntercom()
  const shouldGetAccount =
    user?.user_metadata?.account &&
    !isAccountLoading &&
    apiVersion &&
    !account &&
    jwt &&
    userEmailVerified

  useEffect(() => {
    if (account && jwt && userEmailVerified) {
      dispatch(getIntercomHash())
        // Redux Toolkit isn't great at typing what is returned from createAsyncThunk
        // and thinks we're returning nothing. Instructions on how to change that really
        // aren't clear
        // @ts-ignore
        .unwrap()
        .then((userHash: string) => {
          identifyIntercomUser(
            user,
            userHash,
            companyId,
            companyName,
            updateIntercom,
            shutdownIntercom,
            isAdmin
          )
        })
    }
  }, [
    updateIntercom,
    shutdownIntercom,
    account,
    dispatch,
    jwt,
    userEmailVerified,
    user,
    companyId,
    companyName,
    isAdmin,
  ])

  useEffect(() => {
    if (shouldGetAccount) {
      refetchAccount()
    }
  }, [shouldGetAccount, refetchAccount])

  useEffect(() => {
    if (!apiVersion && jwt && user && userEmailVerified) {
      dispatch(getAPIVersion())
    }
  }, [dispatch, jwt, user, apiVersion, userEmailVerified])

  useEffect(() => {
    if (company && !isAdmin) {
      updateIntercom({
        customAttributes: {
          company_id: company?.id
            ? getIntercomCompanyId(company.id)
            : undefined,
          company_name: company.name,
        },
      })
    }
  }, [company, dispatch, isAdmin, updateIntercom])
}
