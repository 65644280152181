import { FunctionComponent } from 'react'

import { Button } from '@fairhq/common'

import { Roles } from 'hooks/useAuthRoles'
import { useGetUsersQuery } from 'store/account/accountApiWithQuery'
import { useAppSelector } from 'store/hooks'
import { State } from 'store/state'
import { TableCell, TableRow } from 'ui-kit/Table'

import { AccountAndCompany } from './Admin'

interface AdminListItemProps {
  account: AccountAndCompany
  onClick: (a: AccountAndCompany) => void
}

export const AdminListItem: FunctionComponent<AdminListItemProps> = ({
  account,
  onClick,
}) => {
  const { viewingCompanyId } = useAppSelector((state: State) => ({
    viewingCompanyId: state.apiHeadersReducer.companyId,
  }))

  const { data: users } = useGetUsersQuery({
    accountId: account.id,
  })

  const owner = users?.find(user =>
    user.app_metadata?.roles.includes(Roles.OWNER)
  )
  return (
    <TableRow
      sx={{
        color: 'grey500',
        ':hover': { bg: 'grey100' },
        fontWeight: viewingCompanyId === account.company.id ? 'bold' : 'none',
      }}
    >
      <TableCell>{account.company.name}</TableCell>
      <TableCell>{account.company.id}</TableCell>
      <TableCell>{account.id}</TableCell>
      <TableCell>
        <Button
          disabled={!owner?.user_metadata?.onboarding?.hidden}
          variant="primary"
          onClick={() => onClick(account)}
        >
          view
        </Button>
      </TableCell>
    </TableRow>
  )
}
