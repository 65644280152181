import { pick } from 'lodash'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { PASSWORD_REGEXP } from 'const'

export default function useSchema(fields: string[]) {
  const { t } = useTranslation()
  const schema = {
    password: yup
      .string()
      .matches(PASSWORD_REGEXP, t('form.password.invalid'))
      .required(t('form.required')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('form.password.confirmPasswordNotMatch'))
      .required(t('form.required')),
    email: yup
      .string()
      .email(t('form.email.invalid'))
      .required(t('form.required')),
    toc: yup.boolean().required(t('form.required')),
    consent: yup.boolean().required(t('form.required')),
    level: yup.string().required(t('form.required')),
    department: yup.string(),
    employment_type: yup.string(),
    vat: yup.string(),
    setup: yup.string(),
    workingHours: yup.number().min(0, t('form.min', { min: 0 })),
    salary: yup.number().min(0, t('form.min', { min: 0 })),
    bonus: yup.number().min(0, t('form.min', { min: 0 })),
    name: yup.string().required(t('form.required')),
    sector: yup.string().required(t('form.required')),
    size: yup.string().required(t('form.required')),
    location: yup.string().required(t('form.required')),
    firstName: yup.string().required(t('form.required')),
    lastName: yup.string().required(t('form.required')),
    role: yup.string(),
    role_other: yup.string().when('role', {
      is: (val: any) => val === 'other',
      then: yup.string().required(t('form.required')),
    }),
    city: yup.string(),
    country: yup.string(),
    line1: yup.string().required(t('form.required')),
    line2: yup.string(),
    postal_code: yup.string().required(t('form.required')),
    state: yup.string(),
  }

  return yup.object().shape(pick(schema, fields) as any) as any
}
