import { useState, useEffect, useMemo, FunctionComponent } from 'react'

import { User } from '@auth0/auth0-spa-js'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Heading } from '@theme-ui/components'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { useIntercom } from 'react-use-intercom'

import useSchema from 'hooks/use-yup-schema'
import { Layout } from 'layout/Layout'
import { updateUserMetadata } from 'store/account/accountSlice'
import { State } from 'store/state'
import Button from 'ui-kit/Button'
import Field from 'ui-kit/Field'
import { Select } from 'ui-kit/Select'
import { convertToIntercomUser } from 'utils/convertToIntercomUser'
import { rolesOptions } from 'utils/rolesOptions'

import { useAppDispatch, useAppSelector } from '../../store/hooks'

import { BackLink } from './BackLink'
import { SettingsSidebar } from './SettingsSidebar'

type FormData = {
  firstName: string
  lastName: string
  email: string
  role: string
  role_other: string
}

export const EditPersonalDetails: FunctionComponent = () => {
  const { t } = useTranslation()
  const schema = useSchema([
    'firstName',
    'lastName',
    'email',
    'role',
    'role_other',
  ])
  const dispatch = useAppDispatch()
  const [sending, setSending] = useState(false)
  const [redirect, setRedirect] = useState('')
  const { update: updateIntercom } = useIntercom()

  const { user, loading } = useAppSelector(
    (state: State) => ({
      user: state.authReducer.user,
      loading: state.accountReducer.loading,
    }),
    shallowEqual
  )
  const isGoogleAuth = (user?.sub || user?.user_id)?.includes('google')
  const defaultValues = useMemo(
    () => ({
      firstName: user?.given_name || '',
      lastName: user?.family_name || '',
      email: user?.email || '',
      role: user?.user_metadata?.role || '',
      role_other: user?.user_metadata?.role_other || '',
    }),
    [user]
  )

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(schema),
  })
  const roleFieldValue = watch('role')

  const onSubmit = ({
    firstName,
    lastName,
    email,
    role,
    role_other,
  }: FormData) => {
    dispatch(
      updateUserMetadata({
        user: isGoogleAuth
          ? {}
          : {
              email,
              given_name: `${firstName}`,
              name: `${firstName} ${lastName}`,
              family_name: `${lastName}`,
              nickname: `${firstName}`,
            },
        metadata: { ...user?.user_metadata, role, role_other },
      })
    )
      // Redux Toolkit isn't great at typing what is returned from createAsyncThunk
      // and thinks we're returning nothing. Instructions on how to change that really
      // aren't clear
      // @ts-ignore
      .unwrap()
      .then((updatedUser: User) => {
        updateIntercom(convertToIntercomUser(updatedUser))
      })
    setSending(true)
  }

  // update input values when data are fetched
  useEffect(() => {
    if (user && roleFieldValue === '') {
      reset(defaultValues)
    }
  }, [user, reset, defaultValues, roleFieldValue])

  useEffect(() => {
    if (sending && !loading) {
      setRedirect('/settings/account')
    }
  }, [sending, loading, setRedirect])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <Layout sidebar={<SettingsSidebar />} maxContentWidth={660} dark>
      <BackLink
        to="/settings/account"
        i18nKey="nav.settings.account"
        setRedirect={setRedirect}
      />

      <Heading variant="subtitle" sx={{ mb: 8 }}>
        {t('settings.personalEdit')}
      </Heading>

      {!!user && (
        <Box sx={{ maxWidth: 480 }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid gap="4" sx={{ mb: 6 }}>
              <Field
                label={t('form.firstName.label')}
                placeholder={t('form.firstName.placeholder')}
                error={errors.firstName?.message}
                {...register('firstName')}
                disabled={isGoogleAuth}
              />

              <Field
                label={t('form.lastName.label')}
                placeholder={t('form.lastName.placeholder')}
                error={errors.lastName?.message}
                {...register('lastName')}
                disabled={isGoogleAuth}
              />

              <Field
                label={t('form.email.label')}
                placeholder={t('form.email.placeholder')}
                type="email"
                error={errors.email?.message}
                {...register('email')}
                disabled={isGoogleAuth}
              />

              <Box>
                <Field
                  as={Select}
                  label={t('form.role.label')}
                  placeholder={t('form.role.placeholder')}
                  error={errors.role?.message}
                  {...register('role')}
                >
                  {rolesOptions.map(role => (
                    <option key={role.value} value={role.value}>
                      {t(role.label)}
                    </option>
                  ))}
                </Field>
                {roleFieldValue === 'other' && (
                  <Field
                    placeholder={t('form.role_other.placeholder')}
                    error={errors.role_other?.message}
                    {...register('role_other')}
                    sx={{ mt: 2 }}
                  />
                )}
              </Box>
            </Grid>

            <Button
              type="button"
              variant="secondary"
              onClick={() => setRedirect('/settings/account')}
              sx={{ mr: 3 }}
              size="large"
            >
              {t('cancel')}
            </Button>
            <Button type="submit" size="large" loading={sending}>
              {t('saveChanges')}
            </Button>
          </form>
        </Box>
      )}
    </Layout>
  )
}
