import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { shallowEqual } from 'react-redux'

import { useGetAccountQuery } from 'store/account/accountApiWithQuery'
import { apiHeadersActions } from 'store/apiHeaders/apiHeadersSlice'
import {
  useGetApiVersionQuery,
  useGetSessionsQuery,
} from 'store/company/companyApiWithQuery'
import { State } from 'store/state'

import { useAppDispatch, useAppSelector } from '../store/hooks'

import { Roles } from './useAuthRoles'

export const useGetApiHeaderData = () => {
  const { user } = useAuth0()
  const dispatch = useAppDispatch()
  const { companyId, accountId, apiVersion, sessionId, jwt } = useAppSelector(
    (state: State) => ({
      companyId: state.apiHeadersReducer.companyId,
      accountId: state.apiHeadersReducer.accountId,
      apiVersion: state.apiHeadersReducer.apiVersion,
      sessionId: state.apiHeadersReducer.sessionId,
      jwt: state.authReducer.jwt,
    }),
    shallowEqual
  )

  const isAdmin = user?.['https://fairhq.co/app_metadata']?.roles?.includes(
    Roles.ADMIN
  )
  const companyIdFromUser =
    user?.['https://fairhq.co/user_metadata']?.companies?.[0]
  useEffect(() => {
    if (!isAdmin && companyIdFromUser) {
      dispatch(apiHeadersActions.setCompanyId(companyIdFromUser))
    }
  }, [dispatch, isAdmin, companyIdFromUser])

  useGetApiVersionQuery(undefined, {
    skip: !jwt,
  })

  useGetSessionsQuery(undefined, {
    skip: !jwt,
  })

  useGetAccountQuery({ apiVersion }, { skip: !jwt })

  return isAdmin || !!(companyId && accountId && apiVersion && sessionId)
}
