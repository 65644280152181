import { http } from 'utils/http'

import { Changelog } from './types'

export const insightApi = {
  getBenchmarkOptionsByGroup(getState: () => unknown, group = '') {
    return http.get(getState, `insights/benchmark/${group}`)
  },
  getChangelog(getState: () => unknown, changelog: Changelog) {
    return http.post(getState, `insights/changelog`, changelog)
  },
}
